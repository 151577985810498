import React from 'react';
import { Carousel } from 'react-bootstrap';
import WLImage from '../assets/business-1.jpg';
import ITImage from '../assets/Business2.jpg';
import TEImage from '../assets/Telecommunication.jpg';
import OSImage from '../assets/geometric.jpg';
import FinanceImage from '../assets/finance3.jpg';
import Navbar from '../Navbar/Navbar';
import './CarouselComponent.css'; // Assuming you have this CSS file for the styles

const CarouselComponent = () => {
  return (
    <div>
      <Navbar />
      <Carousel>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={WLImage}
            width={800}
            height={500}
            alt="First slide"
          />
      <Carousel.Caption className="caption-zoom">
  <h1 style={{ fontFamily: 'initial', paddingBottom: '20px',fontWeight:'700',textShadow: '2px 2px 8px rgba(0, 0, 0, 0.5)'}}>WELCOME</h1> {/* Reduce marginBottom to bring closer */}
  <h1 style={{ fontFamily: 'initial',paddingBottom: '100px', marginTop: '0' ,}}>Bharat On Air Corporate (P) Limited (BOAC)</h1> {/* Remove marginTop to bring closer */}
</Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            width={800}
            height={500}
            src={ITImage}
            alt="Third slide"
          />
          <Carousel.Caption className="caption-slide">
            <h1 style={{fontFamily:'fantasy',paddingBottom: '20px',textShadow: '2px 2px 8px rgba(0, 0, 0, 0.5)'}}>IT SERVICES</h1>
            <h3 style={{fontFamily:'-moz-initial',paddingBottom: '100px', marginTop: '0',textShadow: '2px 2px 8px rgba(0, 0, 0, 0.5)'}}>Technology is best when it brings people together.</h3>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={TEImage}
            width={800}
            height={500}
            alt="Second slide"
          />
          <Carousel.Caption className="caption-rotate">
            <h1 style={{fontFamily:'initial',paddingTop:'65px',paddingBottom: '20px',fontWeight:'700', textShadow: '2px 2px 8px rgba(0, 0, 0, 0.5)' }}>TELECOMMUNICATION SERVICES</h1>
            <h3 style={{fontFamily:'initial',paddingBottom: '100px', marginTop: '0',textShadow: '2px 2px 8px rgba(1, 1, 0, 5)'}}>The Best Fastest Network</h3>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={FinanceImage}
            width={800}
            height={500}
            alt="Second slide"
          />
          <Carousel.Caption className="caption-fade">
            <h1 style={{fontFamily:'fantasy',paddingBottom: '20px',textShadow: '2px 2px 8px rgba(0, 0, 0, 0.5)'}}>FINANCE</h1>
            <h3 style={{fontFamily:'monospace',paddingBottom: '100px', marginTop: '0',textShadow: '2px 2px 8px rgba(0, 0, 0, 0.5)'}}>Plan, Invest, Secure, Achieve</h3>
          </Carousel.Caption>
        </Carousel.Item>
        <Carousel.Item>
          <img
            className="d-block w-100"
            src={OSImage}
            width={800}
            height={500}
            alt="Second slide"
          />
          <Carousel.Caption className="caption-zoom">
            <h1 style={{fontFamily:'fantasy',paddingBottom: '20px',textShadow: '2px 2px 8px rgba(0, 0, 0, 0.10)'}}>OUTSOURCING</h1>
            <h3 style={{fontFamily:'revert-layer',paddingBottom: '100px', marginTop: '0',textShadow: '2px 2px 8px rgba(0, 0, 0, 0.5)'}}>Smart business strategy</h3>
          </Carousel.Caption>
        </Carousel.Item>
      </Carousel>
    </div>
  );
};

export default CarouselComponent;
