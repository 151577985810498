import React from 'react'
import BusinessImg from '../assets/computer.jpg'
import Footer from '../Footer/Footer'
import CardContainer from '../Card/CardContainer'
import CardAni from '../Card/CardAni';
import Carousel from '../Carousel/CarouselComponent'
import MessangerInfo from '../MessengerInfo/OurProducts';
const Home = () => {
  return (
    <div>
      <Carousel />
      <div class="container-about">
        <div class="text">
          <h6 style={{ color: '#010b96', }}><i>“A Powerful Success business comes from Powerful People”</i></h6>
          <h1 style={{ color: '#010b96',fontFamily:'sans-serif', }}>Welcome to Bharat OnAir Corporate-Your Trusted Services</h1>
          <p style={{ alignItems: 'center', color: '#010b96' }}>Bharat On Air Corporate (P) Limited (BOAC) is India’s leading business services provider, leveraging our extensive domain knowledge and future-ready digital platforms to drive client productivity through outsourced solutions.</p>
          <p class="py-3" style={{ alignItems: 'center', color: '#010b96' }}>We provide a host of technology-enabled staffing and managed outsourcing services across processes such as sales & marketing, customer care, after-sales service, back-office operations, telecom operations, manufacturing operations, facilities and security management, HR & F&A operations, IT & mobility services, etc.
            <br />Our passion for delivering exceptional services, augmented by proprietary digital platforms, has strongly established our credentials as India’s largest employer in the private sector and the biggest integrated business services provider in the country.
            A core value driving our business is constantly making the workforce more productive. </p>
        </div>
        <div class="image">
          <img src={BusinessImg} alt="Description of the image" />
          <p style={{ alignItems: 'left',textAlign:'left', color: '#010b96', paddingTop: '40px' }}>Our business strategy is aligned with this, including training and skill development for better employability, helping job seekers easily find employment opportunities, digitising workflows, and providing social security benefits to a wider employable population</p>
        </div>
      </div>
      <MessangerInfo/>
      <section>
        <div class="services position-relative">
          <div class="container py-3 py-lg-4">
            <h2 class="text-uppercase text-center mb-2 mb-lg-4" style={{ alignItems: 'center', textAlign: 'center', color: '#010b96', paddingBottom: '5px' ,paddingTop:20}}>Our Services</h2>
            <p class="mb-md-4 col-lg-8 text-center mx-auto" style={{
              fontFamily: "Arial",
              fontSize: '16px',
              fontWeight: 'bold',
              color: '#1d4ed8'
            }}>"We provide a range of essential business services including Telecommunications, IT solutions, Labor contracts, HR consulting, Security, OutSourcing, Finance, and Transportation. Our comprehensive offerings streamline operations, enhance security, optimize finances, and ensure seamless connectivity, empowering businesses to thrive in today's competitive landscape."</p>
          </div>
        </div>
      </section>
      <CardAni />
      <CardContainer />
      <Footer />
    </div>
  )
}

export default Home