import React, { useEffect } from 'react';
import Navbar from '../Navbar/Navbar';
import Transport from '../assets/TransportService.jpg';
import Footer from '../Footer/Footer';
import './Contract.css';

const TransportService = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Navbar />
      <section>
        <div className="container-about">
          <div className="text">
            <h1 style={{ color: '#010b96' }}>Transport Services Outsourcing</h1>
            <p style={{ color: '#010b96' }}>
            Transport services outsourcing involves hiring external providers to manage logistics and transportation needs. This enables businesses to reduce costs associated with owning and operating their own fleet while ensuring timely delivery of goods. Specialized logistics providers offer access to advanced technology, expertise, and a vast transportation network, leading to improved efficiency. However, businesses may experience reduced control over delivery processes, so establishing clear service agreements with providers is essential to ensure consistent performance.
            </p>
            <h5 style={{ color: '#010b96' }}>Responsibilities</h5>
            <ul style={{ color: '#010b96' }}>
              <li>Vehicle maintenance and management</li>
              <li>Driver recruitment and training</li>
              <li>Route optimization and scheduling</li>
            </ul>
            <h5 style={{ color: '#010b96' }}>Deliverables</h5>
            <ul style={{ color: '#010b96' }}>
              <li>Safe and timely transportation</li>
              <li>Meeting service level agreements (SLAs)</li>
              <li>Compliance with transportation regulations</li>
            </ul>
          </div>
          <div className="image">
            <img src={Transport} width={'100%'} style={{ height: 400 }} alt="Contract Labour" />
          </div>
        </div>
        <Footer />
      </section>
    </div>
  );
};

export default TransportService;
