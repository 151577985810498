import React, { useEffect } from 'react';
import Navbar from '../Navbar/Navbar';
import Contract from '../assets/contractService.jpg';
import Footer from '../Footer/Footer';
import './Contract.css';

const ContractLabour = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Navbar />
      <section>
        <div className="container-about">
          <div className="text">
            <h1 style={{ color: '#010b96' }}>Contract Labour Outsourcing</h1>
            <p style={{ color: '#010b96' }}>
              Contract labour outsourcing has become an essential component for companies that need to maintain flexibility in their workforce without committing to permanent hires. This practice allows businesses to respond swiftly to changes in demand, ensuring that they have the right number of employees for short-term projects or seasonal peaks. By outsourcing contract labor, companies avoid the complexities of recruiting, training, and managing employees directly, leaving these tasks to third-party agencies that specialize in providing temporary workers.
            </p>
            <h5 style={{ color: '#010b96' }}>Responsibilities</h5>
            <ul style={{ color: '#010b96' }}>
              <li>HR strategy development and implementation</li>
              <li>Recruitment and talent management</li>
              <li>Employee relations and conflict resolution</li>
            </ul>
            <h5 style={{ color: '#010b96' }}>Deliverables</h5>
            <ul style={{ color: '#010b96' }}>
              <li> Improved HR processes and systems</li>
              <li>Enhanced employee engagement and retention</li>
              <li>Compliance with employment laws and regulations</li>
            </ul>
          </div>
          <div className="image">
            <img src={Contract} width={'100%'} style={{ height: 400 }} alt="Contract Labour" />
          </div>
        </div>
        <Footer />
      </section>
    </div>
  );
};

export default ContractLabour;
