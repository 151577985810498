import React, { useState, useEffect } from "react";
import BoacpayImage from '../assets/upiSplash.png';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import {
    FaLock,
    FaGlobe,
    FaExchangeAlt,
    FaRocket,
    FaUserAlt,
    FaHandHoldingUsd,
    FaWallet,
    FaGift,
    FaPeopleArrows,
    FaBell
} from 'react-icons/fa';

function BoacpayInfo() {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 768);
        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div>
            <Navbar />
            <div style={styles.container}>
                <div style={styles.contentContainer}>
                    <div style={styles.content}>
                        <h2 style={styles.heading}>BoacPay Demo</h2>
                        <p style={styles.paragraph}>
                            BoacPay revolutionizes your UPI payment experience with its intuitive and secure platform. Effortlessly transfer money, pay bills, and manage your finances with our user-friendly interface. BoacPay ensures fast, reliable transactions and robust security, making every payment straightforward and hassle-free.
                        </p>
                        <ul style={styles.list}>
                            <li style={styles.listItem}><FaLock style={styles.icon} /> Enhanced Security Features</li>
                            <li style={styles.listItem}><FaGlobe style={styles.icon} /> Cross-Border Payments</li>
                            <li style={styles.listItem}><FaExchangeAlt style={styles.icon} /> Improved Interoperability</li>
                            <li style={styles.listItem}><FaRocket style={styles.icon} /> Advanced Payment Features</li>
                            <li style={styles.listItem}><FaUserAlt style={styles.icon} /> Enhanced User Experience</li>
                            <li style={styles.listItem}><FaHandHoldingUsd style={styles.icon} /> Financial Inclusion Initiatives</li>
                            <li style={styles.listItem}><FaWallet style={styles.icon} /> Integration with Digital Wallets and Cryptocurrencies</li>
                            <li style={styles.listItem}><FaGift style={styles.icon} /> Personalized Offers and Rewards</li>
                            <li style={styles.listItem}><FaPeopleArrows style={styles.icon} /> Community and Social Payments</li>
                            <li style={styles.listItem}><FaBell style={styles.icon} /> Real-Time Transaction Notifications</li>
                        </ul>
                    </div>
                    <div style={styles.mobileImageContainer}>
                        <img
                            src={BoacpayImage}
                            alt="BoacPay Demo"
                            style={isMobile ? styles.mobileImageMobile : styles.mobileImage}
                        />
                    </div>
                </div>
                <div style={styles.additionalContainer}>
                    <div style={styles.row}>
                        <p style={styles.definitionRow}>
                            <strong><FaLock style={styles.definitionIcon} /> Enhanced Security:</strong> Cutting-edge security protocols with multi-factor authentication and real-time fraud detection.
                        </p>
                        <p style={styles.definitionRow}>
                            <strong><FaGlobe style={styles.definitionIcon} /> Cross-Border Payments:</strong> Seamless international transactions with minimal fees and fast processing.
                        </p>
                    </div>
                    <div style={styles.row}>
                        <p style={styles.definitionRow}>
                            <strong><FaExchangeAlt style={styles.definitionIcon} /> Interoperability:</strong> Works effortlessly across banks, gateways, and wallets.
                        </p>
                        <p style={styles.definitionRow}>
                            <strong><FaRocket style={styles.definitionIcon} /> Advanced Payments:</strong> Features like scheduled payments, recurring payments, and instant transfers.
                        </p>
                    </div>
                    <div style={styles.row}>
                        <p style={styles.definitionRow}>
                            <strong><FaUserAlt style={styles.definitionIcon} /> User Experience:</strong> Intuitive interface with streamlined navigation.
                        </p>
                        <p style={styles.definitionRow}>
                            <strong><FaHandHoldingUsd style={styles.definitionIcon} /> Financial Inclusion:</strong> Accessible services for underserved communities.
                        </p>
                    </div>
                    <div style={styles.row}>
                        <p style={styles.definitionRow}>
                            <strong><FaWallet style={styles.definitionIcon} /> Wallet & Crypto:</strong> Integration with digital wallets and cryptocurrencies.
                        </p>
                        <p style={styles.definitionRow}>
                            <strong><FaGift style={styles.definitionIcon} /> Offers & Rewards:</strong> Personalized discounts and cashback incentives.
                        </p>
                    </div>
                    <div style={styles.row}>
                        <p style={styles.definitionRow}>
                            <strong><FaPeopleArrows style={styles.definitionIcon} /> Social Payments:</strong> Facilitate payments within social groups and communities.
                        </p>
                        <p style={styles.definitionRow}>
                            <strong><FaBell style={styles.definitionIcon} /> Real-Time Notifications:</strong> Instant updates for every transaction.
                        </p>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

const styles = {
    container: { display: "flex", flexDirection: "column", padding: "20px", maxWidth: "1200px", margin: "0 auto", backgroundColor: "#fff" },
    contentContainer: { display: "flex", flexDirection: "row", justifyContent: "space-between", alignItems: "center", marginBottom: "20px" },
    content: { flex: 1, paddingRight: "20px" },
    heading: { color: '#010b96', fontWeight: 700 },
    paragraph: { color: '#010b96' },
    list: { listStyleType: "square" },
    listItem: { marginBottom: "10px", color: '#010b96', fontWeight: 700, display: 'flex', alignItems: 'center' },
    icon: { marginRight: "10px", fontSize: "1.2em", color: '#010b96' },
    mobileImageContainer: { flex: 1, display: "flex", justifyContent: "center" },
    mobileImage: { maxWidth: "40%", height: "auto", borderRadius: "10px", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" },
    mobileImageMobile: { maxWidth: "100%", height: "auto", borderRadius: "10px", boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)" },
    additionalContainer: { width: "100%", backgroundColor: "#fff" },
    row: { display: "flex", flexDirection: "row", marginBottom: "20px" },
    definitionRow: { flex: 1, padding: "15px", margin: "5px", backgroundColor: "#f0f4ff", borderLeft: "5px solid #010b96", borderRadius: "5px", color: '#010b96', fontWeight: 400, lineHeight: "1.5" },
    definitionIcon: { marginRight: "10px", fontSize: "1.2em", color: '#010b96' }
};

export default BoacpayInfo;
