import React, { useState, useEffect } from 'react';
import Navbar from '../../Navbar/Navbar';
import Footer from '../../Footer/Footer';
import { useLocation } from 'react-router-dom';
import './Checkout.css';

const Checkout = () => {
  const location = useLocation();
  const { item, planTitle } = location.state || {};
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    address: '',
    paymentMethod: 'Credit Card',
    cardNumber: '',
    expirationDate: '',
    cvv: '',
    amount: '', // Set amount from item details
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      const response = await fetch('http://192.168.0.135:3000/process-payment', {
        method: 'POST',
        headers: {
          'Content-Type': 'application/json',
        },
        body: JSON.stringify({ ...formData, item }),
      });

      const result = await response.json();

      if (response.ok && result.success) {
        alert('Payment processed successfully');
        // Redirect or handle successful payment here
      } else {
        alert('Payment failed: ' + result.message);
      }
    } catch (error) {
      console.error('Error:', error);
      alert('An error occurred during payment processing');
    }
  };

  return (
    <div>
      <Navbar />
      <div className="checkout-container">
        <h1 style={{ color: '#010b96' }}>Checkout</h1>
        {planTitle && <h2 style={{ color: '#010b96' }}>Selected: {planTitle}</h2>}
        {item && (
          <div className="item-details">
            <h3>Item: {item.name}</h3>
            <p>Price: ${item.price}</p>
          </div>
        )}
        <form className="checkout-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="name" style={{ color: '#010b96' }}>Name</label>
            <input
              type="text"
              id="name"
              name="name"
              value={formData.name}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="email" style={{ color: '#010b96' }}>Email</label>
            <input
              type="email"
              id="email"
              name="email"
              value={formData.email}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="phone" style={{ color: '#010b96' }}>Phone</label>
            <input
              type="tel"
              id="phone"
              name="phone"
              value={formData.phone}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="address" style={{ color: '#010b96' }}>Address</label>
            <input
              type="text"
              id="address"
              name="address"
              value={formData.address}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="amount" style={{ color: '#010b96' }}>Amount</label>
            <input
              type="number"
              id="amount"
              name="amount"
              value={formData.amount}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="paymentMethod" style={{ color: '#010b96' }}>Payment Method</label>
            <select
              id="paymentMethod"
              name="paymentMethod"
              value={formData.paymentMethod}
              onChange={handleChange}
            >
              <option value="Credit Card" style={{ color: '#010b96' }}>Credit Card</option>
              <option value="Debit Card" style={{ color: '#010b96' }}>Debit Card</option>
              <option value="PayPal" style={{ color: '#010b96' }}>PayPal</option>
            </select>
          </div>
          {formData.paymentMethod === 'Credit Card' && (
            <>
              <div className="form-group">
                <label htmlFor="cardNumber" style={{ color: '#010b96' }}>Card Number</label>
                <input
                  type="text"
                  id="cardNumber"
                  name="cardNumber"
                  value={formData.cardNumber}
                  onChange={handleChange}
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="expirationDate" style={{ color: '#010b96' }}>Expiration Date</label>
                <input
                  type="text"
                  id="expirationDate"
                  name="expirationDate"
                  value={formData.expirationDate}
                  onChange={handleChange}
                  placeholder="MM/YY"
                  required
                />
              </div>
              <div className="form-group">
                <label htmlFor="cvv" style={{ color: '#010b96' }}>CVV</label>
                <input
                  type="text"
                  id="cvv"
                  name="cvv"
                  value={formData.cvv}
                  onChange={handleChange}
                  required
                />
              </div>
            </>
          )}
          <button type="submit" className="checkout-button">
            Submit Payment
          </button>
        </form>
      </div>
      <Footer />
    </div>
  );
};

export default Checkout;
