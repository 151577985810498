import React, { useEffect } from 'react';
import Navbar from '../Navbar/Navbar';
import Security from '../assets/SecurityService.jpg';
import Footer from '../Footer/Footer';
import './Contract.css';

const SecurityService = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Navbar />
      <section>
        <div className="container-about">
          <div className="text">
            <h1 style={{ color: '#010b96' }}>Security Services Outsourcing</h1>
            <p style={{ color: '#010b96' }}>
            Security services outsourcing allows businesses to delegate the responsibility of asset and personnel protection to external companies. This can include hiring security personnel, installing surveillance systems, and managing access control. Outsourcing security services is cost-effective, providing access to trained professionals and advanced technology. While it reduces the need for in-house security teams, businesses must ensure they select reliable providers and maintain oversight to address concerns over control and data confidentiality.
            </p>
            <h5 style={{ color: '#010b96' }}>Responsibilities</h5>
            <ul style={{ color: '#010b96' }}>
              <li>Security personnel recruitment and training</li>
              <li>Access control and surveillance</li>
              <li>Emergency response and crisis management</li>
            </ul>
            <h5 style={{ color: '#010b96' }}>Deliverables</h5>
            <ul style={{ color: '#010b96' }}>
              <li>Secure environment for employees and assets</li>
              <li>Meeting security protocols and standards</li>
              <li>Compliance with security regulations</li>
            </ul>
          </div>
          <div className="image">
            <img src={Security} width={'100%'} style={{ height: 400 }} alt="Contract Labour" />
          </div>
        </div>
        <Footer />
      </section>
    </div>
  );
};

export default SecurityService;
