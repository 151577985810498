import React, { useState, useEffect } from "react";
import MerchantImage from '../assets/merchant.png';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import { 
    FaUserCog, 
    FaCreditCard, 
    FaFileInvoice, 
    FaBox, 
    FaChartLine, 
    FaUsers, 
    FaShieldAlt, 
    FaUserShield, 
    FaHeadset, 
    FaCogs 
} from 'react-icons/fa';

function MerchantInfo() {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 768);

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div>
            <Navbar/>
            <div style={styles.container}>
                <div style={styles.contentContainer}>
                    <div style={styles.content}>
                        <h2 style={styles.heading}>Merchant Demo</h2>
                        <p style={styles.paragraph}>
                            Our Merchant app offers a streamlined, secure platform for managing your business. It supports product listings, order management, customer interactions, and more. Enjoy a seamless experience with features designed to enhance your business operations and customer satisfaction.
                        </p>
                        <ul style={styles.list}>
                            <li style={styles.listItem}>
                                <FaUserCog style={styles.icon} /> User Registration & Authentication
                            </li>
                            <li style={styles.listItem}>
                                <FaCreditCard style={styles.icon} /> Payment Processing
                            </li>
                            <li style={styles.listItem}>
                                <FaFileInvoice style={styles.icon} /> Invoicing and Billing
                            </li>
                            <li style={styles.listItem}>
                                <FaBox style={styles.icon} /> Order Management
                            </li>
                            <li style={styles.listItem}>
                                <FaChartLine style={styles.icon} /> Analytics and Reporting
                            </li>
                            <li style={styles.listItem}>
                                <FaUsers style={styles.icon} /> Customer Management
                            </li>
                            <li style={styles.listItem}>
                                <FaShieldAlt style={styles.icon} /> Security Features
                            </li>
                            <li style={styles.listItem}>
                                <FaUserShield style={styles.icon} /> User Roles and Permissions
                            </li>
                            <li style={styles.listItem}>
                                <FaHeadset style={styles.icon} /> Customer Support and Help
                            </li>
                            <li style={styles.listItem}>
                                <FaCogs style={styles.icon} /> Inventory Management
                            </li>
                        </ul>
                    </div>
                    <div style={styles.mobileImageContainer}>
                        <img
                            src={MerchantImage}
                            alt="Merchant Demo"
                            style={isMobile ? styles.mobileImageMobile : styles.mobileImage}
                        />
                    </div>
                </div>
                <div style={styles.additionalContainer}>
                    <div style={styles.row}>
                        <p style={styles.definitionRow}>
                            <strong><FaUserCog style={styles.icon} />User Registration & Authentication:</strong> Manage user accounts with secure login and verification processes.
                        </p>
                        <p style={styles.definitionRow}>
                            <strong><FaCreditCard style={styles.icon} />Payment Processing:</strong> Integrates with payment gateways to ensure secure and efficient transactions.
                        </p>
                    </div>
                    <div style={styles.row}>
                        <p style={styles.definitionRow}>
                            <strong><FaFileInvoice style={styles.icon} />Invoicing and Billing:</strong> Automate invoicing, billing, and payment reminders to streamline operations.
                        </p>
                        <p style={styles.definitionRow}>
                            <strong><FaBox style={styles.icon} />Order Management:</strong> Track and manage orders efficiently, from placement to fulfillment.
                        </p>
                    </div>
                    <div style={styles.row}>
                        <p style={styles.definitionRow}>
                            <strong><FaChartLine style={styles.icon} />Analytics and Reporting:</strong> Gain insights with real-time data on sales, inventory, and customer behavior.
                        </p>
                        <p style={styles.definitionRow}>
                            <strong><FaUsers style={styles.icon} />Customer Management:</strong> Maintain a detailed database of customers and manage interactions.
                        </p>
                    </div>
                    <div style={styles.row}>
                        <p style={styles.definitionRow}>
                            <strong><FaShieldAlt style={styles.icon} />Security Features:</strong> Implement advanced security protocols to protect user data and transactions.
                        </p>
                        <p style={styles.definitionRow}>
                            <strong><FaUserShield style={styles.icon} />User Roles and Permissions:</strong> Assign specific roles and permissions to manage access control within the app.
                        </p>
                    </div>
                    <div style={styles.row}>
                        <p style={styles.definitionRow}>
                            <strong><FaHeadset style={styles.icon} />Customer Support and Help:</strong> Provide integrated customer support features to assist users effectively.
                        </p>
                        <p style={styles.definitionRow}>
                            <strong><FaCogs style={styles.icon} />Inventory Management:</strong> Keep track of your stock levels, automate reordering, and manage suppliers to ensure your business runs smoothly.
                        </p>
                    </div>
                </div>
            </div>
            <Footer/>
        </div>
    );
}

const styles = {
    container: {
        display: "flex",
        flexDirection: "column",
        padding: "20px",
        width: "100%", // Full width of the viewport
        margin: "0 auto", // Center-aligned
        backgroundColor: "#fff",
    },
    contentContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "20px",
    },
    content: {
        flex: 1,
        paddingRight: "20px",
    },
    heading: {
        color: '#010b96',
        fontWeight: 700,
    },
    paragraph: {
        color: '#010b96',
    },
    list: {
        listStyleType: "none",
        padding: 0,
    },
    listItem: {
        display: "flex",
        alignItems: "center",
        marginBottom: "10px",
        color: '#010b96',
        fontWeight: 700,
    },
    icon: {
        marginRight: "10px",
        fontSize: "1.2em",
        color: '#010b96',
    },
    mobileImageContainer: {
        flex: 1,
        display: "flex",
        justifyContent: "center",
    },
    mobileImage: {
        maxWidth: "40%",
        height: "auto",
        borderRadius: "10px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    },
    mobileImageMobile: {
        maxWidth: "100%",
        height: "auto",
        borderRadius: "10px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    },
    additionalContainer: {
        width: "100%",
        backgroundColor: "#fff",
    },
    row: {
        display: "flex",
        flexDirection: "row", // Changed to column for better readability
        marginBottom: "20px",
    },
    definitionRow: {
        flex: 1,
        padding: "15px",
        margin: "5px",
        backgroundColor: "#f0f4ff",
        borderLeft: "5px solid #010b96",
        borderRadius: "5px",
        color: '#010b96',
        fontWeight: 400,
        lineHeight: "1.5",
    },
};

export default MerchantInfo;
