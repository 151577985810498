
import './App.css';
import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";
import Home from './components/Screens/Home';
import Contact from './components/Screens/Contact';
import About from './components/Screens/About';
import Finance from './components/Screens/Finance'
import Services from './components/Screens/Services'
import Careers from './components/Screens/Careers'
import BusinessSolutions from './components/Screens/BusinessSolutions'
import Blogs from './components/Screens/Blogs'
import TS from '../src/components/Screens/TS'
import ITServices from './components/Screens/IT-Solutions&Services';
import TeleCommunication from './components/Screens/TeleCommunication';
import HrConsultant from './components/Screens/HRContract-OS';
import OutSourcing from './components/Screens/OutSourcing'
import OurProducts from './components/MessengerInfo/OurProducts'
import MessengerInfo from './components/MessengerInfo/MessengerInfo';
import MerchantInfo from './components/MerchantInfo/MerchantInfo'
import BoacPayInfo from './components/BoacInfo/BoacpayInfo'
import TermsAndConditions from './components/Screens/Terms&Conditions';
import PrivacyPolicy from './components/Screens/PrivacyPolicy';
import TransportService from './components/Screens/TransportService-OS';
import SecurityService from './components/Screens/SecurityServices-OS';
import ITService from './components/Screens/ITServices-OS';
import ContractLabour from './components/Screens/ContractLabour-OS';
import Pricing from './components/Screens/Pricing';
import Checkout from './components/Screens/Checkout/Checkout';
import Refund from './components/Screens/Refund/refund';

function App() {
  return (
    <Router>
      <Routes>
        <Route path="/" element={<Home />}
        ></Route>
        <Route path="/about" element={<About />}
        ></Route>
        <Route path="/businessSolutions" element={<BusinessSolutions />}
        ></Route>
        <Route path="/careers" element={<Careers />}
        ></Route>
        <Route path="/blogs" element={<Blogs />}
        ></Route>
        <Route path="/finance" element={<Finance />}
        ></Route>
        <Route path="/contact" element={<Contact />}
        ></Route>
        <Route path="/about" element={<About />}
        ></Route>
        <Route path="/trustAndSafety" element={<TS />}
        ></Route>
         <Route path="/termsAndConditions" element={<TermsAndConditions />}
        ></Route>
        <Route path="/services" element={<Services />}
        ></Route>
        <Route path="/itservices" element={<ITServices />}
        ></Route>
        <Route path="/telecommunication" element={<TeleCommunication />}
        ></Route>
        
        <Route path="/outSourcing" element={<OutSourcing />}
        ></Route>
          <Route path="/ourProducts" element={<OurProducts />}
        ></Route>
           <Route path="/messengerInfo" element={<MessengerInfo />}
        ></Route>
           <Route path="/merchantInfo" element={<MerchantInfo />}
        ></Route>
           <Route path="/boacPayInfo" element={<BoacPayInfo />}
        ></Route>
          <Route path="/privacyPolicy" element={<PrivacyPolicy />}
        ></Route>
                <Route path="/securityService" element={<SecurityService/>}
        ></Route>
                <Route path="/contractLabour" element={<ContractLabour/>}
        ></Route>
                <Route path="/itService" element={<ITService/>}
        ></Route>
                <Route path="/transport" element={<TransportService/>}
        ></Route>
        <Route path="/hrConsultant" element={<HrConsultant />}
        ></Route>
        <Route path="/pricing" element={<Pricing />}
        ></Route>
         <Route path="/checkout" element={<Checkout />}
        ></Route>
          <Route path="/refund" element={<Refund />}
        ></Route>
      </Routes>
    </Router>
  );
}

export default App;
