import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import { FiArrowRight, FiArrowLeft } from 'react-icons/fi';
import './Swipper.css';
import decon from '../assets/decon.jpg';
import gtl from '../assets/gtl.jpg';
import ssq from '../assets/ssq1.jpg';
import ripl from '../assets/ripl1.jpg';
import Ved from '../assets/ved.jpg';

// Custom arrow components
const NextArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div
      className={`${className} custom-arrow next-arrow`}
      onClick={onClick}
    >
      <FiArrowRight />
    </div>
  );
};

const PrevArrow = (props) => {
  const { className, onClick } = props;
  return (
    <div
      className={`${className} custom-arrow prev-arrow`}
      onClick={onClick}
    >
      <FiArrowLeft />
    </div>
  );
};

const CardWithSlider = () => {
  const settings = {
    dots: true,
    // infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    nextArrow: <NextArrow />,
    prevArrow: <PrevArrow />,
  };

  return (
    <div className="slider-container">
      <h4 className="slider-title" style={{ color: '#010b96',alignItems:"center",textAlign:'center',fontWeight:"700" }}>OUR CLIENTS</h4>
      <Slider {...settings} className="slider-content">
        <div>
          <img src={ripl} style={{width:80}} alt="" className="flip-box-img" />
        </div>
        <div>
          <img src={decon} style={{width:80}} alt="" className="flip-box-img" />
        </div>
        <div>
          <img src={gtl} style={{width:60}} alt="" className="flip-box-img" />
        </div>
        <div>
          <img src={Ved} style={{width:80}} alt="" className="flip-box-img" />
        </div>
        <div>
          <img src={ssq}  style={{width:80}} alt="" className="flip-box-img" />
        </div>
      </Slider>
    </div>
  );
};

export default CardWithSlider;
