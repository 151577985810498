import React, { useEffect } from 'react';
import Navbar from '../Navbar/Navbar'
import telecomImage from '../assets/Telecom.jpg'
import wiredImage from '../assets/image-wired.jpg'
import wirelessImage from '../assets/image-wireless.jpg'
import Footer from '../Footer/Footer'
import backgroundImg from '../assets/back.jpg'
import './Telecommunication.css'
import Swipper from '../Swipper/Swipper';

const TeleCommunication = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>

      <Navbar />
      <section>
        <div class="coinwithhand position-relative">
          <div class="container-about">
            <div class="row align-items-center backgre text">
              <div class="col-12 col-lg-6 text">
                <h1 style={{ color: '#010b96' }}>Telecommunication services provider</h1><p style={{ alignItems: 'center', color: '#010b96' }}>are pleased to introduce ourselves as one of the growing Manpower and Promote digital literacy and bridge the digital divide between urban and rural areas.</p>
                <p style={{ alignItems: 'center', color: '#010b96' }}>Businesses today focus more on their core competencies
                  and often partner with specialists for support functions.
                  At OnAir Corporate, we manage some of the major
                  network requirements for our customers such as 5G & 4G
                  Services, software and hardware upgrades, NOC
                  Management, and KPI Management.</p>
                <p class="py-3" style={{ alignItems: 'center', color: '#010b96' }}>Our clients
                  outsource our services for their requirements such as the
                  rapid growth in telecom services through cloud-based
                  services. The number of alarm points that must be kept
                  track of is scaling to the millions. This is making the older
                  methods of monitoring and trouble-shooting obsolete.
                  With “virtualized” networks coming online, businesses
                  need better assurance capabilities.
                </p>

              </div>
              <div class="col-12 col-lg-6 backgre">
                <div class='image'>
                  <img src={telecomImage} width="450" height="350" alt="" />
                  <p class="py-3" style={{ alignItems: 'center', color: '#010b96' }}>With our Network (Bharat OnAir Corporate) & Service
                    assurance capabilities, OnAir Corporate is stepping up to
                    the test of today’s evolving networks. We are playing a
                    bigger and more strategic role with our benchmarking
                    audits- Physical and KPI.</p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="container text-center">
          <h1 style={{ alignItems: 'center', color: '#010b96' }}>WORKING WITH PROJECTS</h1><br />
          <div class="row" style={{ alignItems: 'center', justifyContent: 'center' }}>
            <div class="col-sm-4" style={{ alignItems: 'center', color: '#010b96' }}>
              <img src={wiredImage} class="img-responsive" style={{ width: "100%" }} alt="Image" />
              <p>Wired</p>
            </div>
            <div class="col-sm-4" style={{ alignItems: 'center', color: '#010b96' }}>
              <img src={wirelessImage} class="img-responsive" style={{ width: "100%" }} alt="Image" />
              <p>Wireless</p>
            </div>
          </div>
        </div>
      </section>
      
      <div> <h1 style={{ alignItems: 'center', color: '#010b96',textAlign:'center' }}>SERVICES OFFERED</h1></div> 
      <div class="card-Container" style={{flexDirection: "row", flexWrap: 'wrap',gap: '10px',justifyContent:'center'}}>
     
  <div class="column">
    <div class="card" style={{
    padding: "16px",
    textAlign: "center",
    backgroundColor: "#f1f1f1",
    width:'200px',height:'80%',   backgroundImage: `url(${backgroundImg})`}}>
      <h3>Transport Equipment</h3>
      <p>Transport equipment services are critical components in modern telecommunications networks.</p>
    </div>
  </div>

  <div class="column">
    <div class="card" style={{
    padding: "15px",
    textAlign: "center",
    backgroundColor: "#f1f1f1",
    width:'200px',height:'80%',backgroundImage: `url(${backgroundImg})`}}>
      <h3>Project Management</h3>
      <p>With Bharat OnAir Corporate, you can trust our experienced project management team to oversee every aspect of your project from inception to completion.</p>
    </div>
  </div>
  
  <div class="column">
    <div class="card" style={{
    padding: "15px",
    textAlign: "center",
    backgroundColor: "#f1f1f1",
    width:'200px',height:'80%',backgroundImage: `url(${backgroundImg})`}}>
      <h3>O & M Services</h3>
      <p>Our team of experienced technicians and engineers will monitor, maintain, and optimize your network infrastructure to ensure maximum uptime and performance. </p>
    </div>
  </div>
  <div class="column">
    <div class="card" style={{
    padding: "15px",
    textAlign: "center",
    backgroundColor: "#f1f1f1",
    width:'200px',height:'80%',backgroundImage: `url(${backgroundImg})`}}>
      <h3>Infratructure</h3>
      <p>Build a solid foundation for your business with our reliable infrastructure solutions.we'll deliver reliable and scalable infrastructure solutions tailored to your business requirements.</p>
    </div>
  </div>
  <div class="column">
    <div class="card" style={{
    padding: "15px",
    textAlign: "center",
    backgroundColor: "#f1f1f1",
    width:'200px',height:'80%',backgroundImage: `url(${backgroundImg})`}}>
      <h3>NOC Serviecs</h3>
      <p>Outsource the management of your network operations to our state-of-the-art NOC facility.</p>
    </div>
  </div>
  <div class="column">
    <div class="card" style={{
    padding: "15px",
    textAlign: "center",
    backgroundColor: "#f1f1f1",
    width:'200px',height:'80%',backgroundImage: `url(${backgroundImg})`}}>
      <h3>RF Services</h3>
      <p>Our team of RF engineers specializes in designing, optimizing, and troubleshooting wireless networks to ensure optimal coverage, capacity, and performance.  </p>
    </div>
  </div>
  <div class="column">
    <div class="card" style={{
    padding: "15px",
    textAlign: "center",
    backgroundColor: "#f1f1f1",
    width:'200px',height:'80%',backgroundImage: `url(${backgroundImg})`}}>
      <h3>Microwave & BTS</h3>
      <p>Deploy reliable and high-performance microwave and BTS solutions with Bharat OnAir Corporate.</p>
    </div>
  </div>
  <div class="column">
    <div class="card" style={{
    padding: "15px",
    textAlign: "center",
    backgroundColor: "#f1f1f1",
    width:'200px',height:'80%',backgroundImage: `url(${backgroundImg})`}}>
      <h3>Logistics Services</h3>
      <p>Streamline your supply chain with our comprehensive logistics services. </p>
    </div>
  </div>

</div>
<Swipper />
      <Footer /></div>
  )
}

export default TeleCommunication