import React, { useEffect } from 'react';
import CardServices from '../Card/CardServices'
import OSimage from '../assets/OSSImage.jpg'
import Navbar from '../Navbar/Navbar'
import Footer from '../Footer/Footer'

const OutSourcing = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Navbar/>
      {/* <section>
    <div class="coinwithhand position-relative">
      <div class="container py-3 py-lg-4">
        <div class="row align-items-center backgre">
          <div class="col-12 col-lg-6">
            <h1 style={{ color: '#010b96' }}>OutSourcing</h1>
            <div>
  
      </div>
          </div>
          <div class="col-12 col-lg-6 backgre">
            <div >
              <img src={OSimage} width="500" height="300" alt="" />
            </div>
          </div>
        </div>
      </div>
    </div>

  </section> */}
  <section>
      <div class="container-about">
      <div class="text">
      <h1 style={{ color: '#010b96' }}>OutSourcing</h1>
      <p style={{ alignItems: 'center', color: '#010b96'}}>Outsourcing is a strategic practice where businesses delegate specific tasks, processes, or functions to external service providers, thereby enabling them to focus on their core competencies and enhance overall efficiency. This approach offers a multitude of benefits, including cost reduction, access to specialized skills, and improved flexibility. By outsourcing non-core functions such as IT support, customer service, or logistics, companies can significantly lower operational costs associated with hiring, training, and maintaining in-house staff and infrastructure. 
      </p>
              </div>
        <div class="image">
        <img src={OSimage} width="500" height="300" alt="" />
        </div>
    </div>

      </section>
     <CardServices/>
     <Footer/>
    </div>
  )
}

export default OutSourcing