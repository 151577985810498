import React, { useState } from 'react';
import { NavLink } from 'react-router-dom';
import logo from '../assets/boaclogoNew.png';
import './Navbar.css';
import 'bootstrap/dist/css/bootstrap.min.css';
import 'bootstrap/dist/js/bootstrap.bundle.min';

const Navbar = () => {
  const [menuOpen, setMenuOpen] = useState(false);
  const toggleMenu = () => {
    setMenuOpen(!menuOpen);
  };
  const closeMenu = () => {
    setMenuOpen(false);
  };
  const togglerButtonStyle = {
    // borderColor: 'rgba(255, 255, 255, 0.5)',
   
  };
  return (
    <nav className="navbar navbar-expand-lg navbar-custom">
      <div className="container-fluid navbar-cont">
      <a href="/" className="navbar-logo" style={{ width: '80px'}}> {/* Adjust color as needed */}
  <img style={{ width: '60px', height: '50px',  }} src={logo} alt="Logo" />
</a>
        <button
          className="navbar-toggler"
          type="button"
          aria-controls="navbarNavDropdown"
          aria-expanded={menuOpen ? 'true' : 'false'}
          aria-label="Toggle navigation"
          onClick={toggleMenu}
          style={togglerButtonStyle}
        >
          <span className="navbar-toggler-icon"></span>
        </button>
        <div className={`collapse navbar-collapse ${menuOpen ? 'show' : ''}`} id="navbarNavDropdown" >
          <ul className="navbar-nav ms-auto">
            <li className="nav-item">
              <NavLink to='/' onClick={closeMenu}>HOME</NavLink>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdownMenuLink"
                role="button"
                aria-expanded="false"
              >
               OUR PRODUCTS
              </a>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                <li className="dropdown-item">
                  <NavLink style={{ color: '#010b96' }} to='/messengerInfo' onClick={closeMenu}>MESSENGER APPLICATION</NavLink>
                </li>
                <li className="dropdown-item">
                  <NavLink style={{ color: '#010b96' }} to='/merchantInfo' onClick={closeMenu}>MERCHANT APPLICATION</NavLink>
                </li>
                <li className="dropdown-item">
                  <NavLink style={{ color: '#010b96' }} to='/boacPayInfo' onClick={closeMenu}>BOACPAY APPLICATION</NavLink>
                </li>
                
              </ul>
            </li>
            <li className="nav-item dropdown">
              <a
                className="nav-link dropdown-toggle"
                href="#"
                id="navbarDropdownMenuLink"
                role="button"
                aria-expanded="false"
              >
                SERVICES
              </a>
              <ul className="dropdown-menu" aria-labelledby="navbarDropdownMenuLink">
                <li className="dropdown-item">
                  <NavLink style={{ color: '#010b96' }} to='/itservices' onClick={closeMenu}>IT SERVICES</NavLink>
                </li>
                <li className="dropdown-item">
                  <NavLink style={{color: '#010b96' }} to='/finance' onClick={closeMenu}>FINANCE SERVICES</NavLink>
                </li>
                <li className="dropdown-item">
                  <NavLink style={{ color: '#010b96' }} to='/telecommunication' onClick={closeMenu}>TELECOMMUNICATION SERVICES</NavLink>
                </li>
                <li className="dropdown-item">
                  <NavLink style={{ color: '#010b96' }} to='/outsourcing' onClick={closeMenu}>OUTSOURCING SERVICES</NavLink>
                </li>
              </ul>
            </li>
            <li className="nav-item">
              <NavLink to='/businessSolutions' onClick={closeMenu}>BUSINESS SOLUTIONS</NavLink>
            </li>
            <li className="nav-item">
              <NavLink to='/pricing' onClick={closeMenu}>PRICING</NavLink>
            </li>
            <li className="nav-item">
              <NavLink to='/careers' onClick={closeMenu}>CAREERS</NavLink>
            </li>
            <li className="nav-item">
              <NavLink to='/about' onClick={closeMenu}>ABOUT</NavLink>
            </li>
            <li className="nav-item">
              <NavLink to='/contact' onClick={closeMenu}>CONTACT US</NavLink>
            </li>
            <li className="nav-item">
              <NavLink to='/trustAndSafety' onClick={closeMenu}>TRUST & SAFETY</NavLink>
            </li>
          </ul>
        </div>
      </div>
    </nav>
  );
};

export default Navbar;
