import React, { useEffect } from 'react';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import ITImage from '../assets/IT-Services-Solution.jpg';
import './ItServices.css'
const ITServices = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Navbar />
      <section>
        <div className="coinwithhand position-relative">
          <div className="container py-3 py-lg-4">
            <div className="row align-items-center backgre">
              <div className="col-12 col-lg-6">
                <h1 style={{ color: '#010b96', fontSize: '2rem' }}  class="text">IT Services</h1>
                <div  class="text">
                  <p style={{ color: '#010b96', fontSize: '1rem', textAlign: 'justify' }}>
                    In today's fast-paced digital landscape, businesses require robust and reliable IT solutions to stay competitive and drive innovation. Our company specializes in delivering comprehensive IT services tailored to meet the unique needs of each client.
                  </p>
                  <p style={{ color: '#010b96', fontSize: '1rem', textAlign: 'justify' }}>
                    From cloud computing and cybersecurity to network infrastructure and software development, we provide end-to-end solutions that enhance efficiency and foster growth. Our team of seasoned professionals leverages the latest technologies and industry best practices to deliver scalable and secure IT environments. By partnering with us, businesses gain access to cutting-edge solutions that streamline operations, protect critical data, and support strategic objectives. Whether you're a startup looking to build a solid IT foundation or an established enterprise seeking to optimize your technology investments, we are committed to delivering exceptional service and unparalleled expertise every step of the way.
                  </p>
                </div>
              </div>
              <div className="col-12 col-lg-6 backgre">
                <div className="image">
                  <img src={ITImage} width="100%" height="auto" alt="IT Services" />
                  <p style={{ color: '#010b96', fontSize: '1rem', textAlign: 'justify', marginTop: '15px' }}>
                    A core value driving our business is constantly making the workforce more productive. Our business strategy is aligned with this, including training and skill development for better employability, helping job seekers easily find employment opportunities, digitizing workflows, and providing social security benefits to a wider employable population.
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="coinwithhand position-relative">
          <div className="container py-3 py-lg-4">
            <div className="row align-items-center backgre">
              <div className="col-12 col-lg-6">
                <h1 style={{ color: '#010b96', fontSize: '2rem' }}>Our Development</h1>
                <p style={{ color: '#010b96', fontSize: '1rem', textAlign: 'justify' }}>
                  We are currently looking for talented individuals to fill the following positions:
                </p>
                <p style={{ color: '#010b96', fontSize: '1rem', textAlign: 'justify' }}>
                  Our company culture is built on mutual respect, transparency, and the drive to excel. We encourage open communication and believe in recognizing and rewarding hard work. Our employees enjoy a fun and engaging workplace where new ideas are always welcome.
                </p>
              </div>
              {/* Uncomment if needed */}
              {/* <div className="col-12 col-lg-6 backgre">
                <div>
                  <img src={JOImage} width="100%" height="auto" alt="Our Development" />
                </div>
              </div> */}
              {/* <AppForm /> */}
            </div>
          </div>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default ITServices;
