import React, { useEffect } from 'react';
import { useNavigate } from 'react-router-dom'; // Import for navigation
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import webImage from '../assets/websitePricing.jpg'; // Ensure this path is correct
import AndroidImage from '../assets/mobilePricing.jpg';
import { CiMail } from "react-icons/ci";
import { FaPhone } from "react-icons/fa6";
import { FaShoppingCart } from 'react-icons/fa'; 
import './Pricing.css';
import { Link } from 'react-router-dom';

const styles = {
  container: {
    fontFamily: 'Arial, sans-serif',
    color: '#010b96',
    padding: '0',
    margin: '0',
    width: '100%',
    boxSizing: 'border-box',
  },
  header: {
    width: '100%',
    backgroundColor: '#ffffff',
    borderBottom: '1px solid #ddd',
  },
  titleContactRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    width: '100%',
    padding: '10px 20px',
  },
  heading: {
    margin: '0',
    fontSize: '1.5em',
  },
  contactInfo: {
    textAlign: 'right',
    fontSize: '0.9em',
    color: '#010b96',
  },
  image: {
    width: '100%',
    height: 'auto',
    display: 'block',
  },
  section: {
    margin: '20px auto',
    maxWidth: '1200px',
    padding: '0 20px',
  },
  pricingPlan: {
    backgroundColor: '#ffffff',
    border: '1px solid #ddd',
    borderRadius: '5px',
    padding: '20px',
    margin: '10px 0',
    width: '100%',
    boxSizing: 'border-box',
  },
  planTitle: {
    color: '#010b96',
    fontWeight: 200,
  },
  planPrice: {
    fontSize: '1.2em',
    fontWeight: 'bold',
  },
  listItem: {
    margin: '5px 0',
  },
  androidPricingContainer: {
    display: 'flex',
    alignItems: 'center',
    marginTop: '20px',
    flexDirection: 'row',
  },
  androidImageContainer: {
    flex: '1',
    textAlign: 'right',
  },
  androidTextContainer: {
    flex: '1',
    paddingLeft: '20px',
  },
  image2: {
    width: '100%',
    height: 'auto',
    display: 'block',
  },
  checkoutLinkContainer: {
    display: 'flex',
    justifyContent: 'flex-end',
    margin: '20px 0',
  },
  checkoutLink: {
    display: 'flex',
    alignItems: 'center',
    color: '#010b96',
    textDecoration: 'none',
    fontSize: '1em',
  },
  '@media (max-width: 768px)': {
    androidPricingContainer: {
      flexDirection: 'column',
      alignItems: 'center',
    },
    androidImageContainer: {
      textAlign: 'center',
      marginTop: '10px',
    },
    androidTextContainer: {
      paddingLeft: '0',
    },
    image2: {
      width: '90%',
      maxWidth: '300px',
      margin: '0 auto',
    },
    contactInfo: {
      paddingTop: '2px',
      fontSize: '0.75em', // Adjust font size for mobile view
    },
    contactInfoPhone: {
      margin: '0',
      padding: '0',
    },
  },
};

const Pricing = () => {
  const navigate = useNavigate();
  // Function to handle navigation to the checkout page with the plan title
  const handlePlanClick = (planTitle) => {
    navigate('/checkout', { state: { planTitle } });
  };

  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div style={styles.container}>
      <div style={styles.header}>
        <Navbar />
      </div>
      <div style={styles.titleContactRow}>
        <h1 style={styles.heading} className="text">Explore Our Pricing Plans for Web & Android/iOS</h1>
        <div style={styles.contactInfo}>
          <p style={{ alignItems: 'center', color: '#010b96' }}>
            <CiMail />
            <a href="mailto:sales@bharatonair.com" style={{ color: '#010b96' }}>
              sales@bharatonair.com
            </a>
          </p>
          <p>
            <FaPhone /> Phone: +91-8925930572
          </p>
        </div>
      </div>
      <img src={webImage} alt="Web Pricing" style={styles.image} />
      <section style={styles.section}>
        <div style={styles.checkoutLinkContainer}>
          <Link to="/refund" className="refund-link" style={{ paddingTop: 20 }}>Request a Refund</Link>
        </div>
        <div>
        <h2 style={{ fontWeight: 700 }}>Websites</h2>
        <div
          style={styles.pricingPlan}
          onClick={() => handlePlanClick('Basic Plan for Website')}
        >
          <h3 style={styles.planTitle}>Basic Plan</h3>
          <p style={styles.planPrice}>Price: ₹3,500-₹40,000</p>
          <ul>
            <li style={styles.listItem}>Static Websites</li>
            <li style={styles.listItem}>Dynamic Websites</li>
            <li style={styles.listItem}>E-commerce Websites</li>
          </ul>
        </div>
        <div
          style={styles.pricingPlan}
          onClick={() => handlePlanClick('Pro Plan for Website')}
        >
          <h3 style={styles.planTitle}>Pro Plan</h3>
          <p style={styles.planPrice}>Price: ₹41,000 - ₹1,50,000</p>
          <ul>
            <li style={styles.listItem}>Content Management Systems (CMS)</li>
            <li style={styles.listItem}>Single Page Applications (SPA)</li>
            <li style={styles.listItem}>Progressive Web Apps (PWA)</li>
            <li style={styles.listItem}>Social Media Websites</li>
            <li style={styles.listItem}>Portfolio Websites</li>
            <li style={styles.listItem}>Corporate Websites</li>
          </ul>
        </div>
        </div>
        <div className="container-about">
          <div className="text">
            <h1 style={{ alignItems: 'center', color: '#010b96' }}>Android/iOS Applications</h1>
            <p style={{ alignItems: 'center', color: '#010b96' }}>
              Our plans provide a range of options from Basic to Premium, each with unique features tailored to different needs. Use our comparison table to find the plan that best suits you. Explore payment options and contact us for custom solutions or additional assistance.
            </p>
            <h6>Comprehensive Guide to Android & iOS App Pricing and Costs</h6>
            <ul>
              <li style={styles.listItem}>Development Costs</li>
              <li style={styles.listItem}>Platform Fees</li>
              <li style={styles.listItem}>Social Media Apps</li>
              <li style={styles.listItem}>Support and Customer Service</li>
              <li style={styles.listItem}>Marketing and Promotion</li>
              <li style={styles.listItem}>Maintenance and Updates</li>
            </ul>
          </div>
          <div className="image">
            <img src={AndroidImage} width="600" height="400" alt="Description of the image" />
          </div>
        </div>
        <div
          style={styles.pricingPlan}
          onClick={() => handlePlanClick('Base Plan for Apps')}
        >
          <h3 style={styles.planTitle}>Base Plan</h3>
          <p style={styles.planPrice}>Price: ₹45,000 - ₹2,50,000</p>
          <ul>
            <li style={styles.listItem}>Productivity Apps</li>
            <li style={styles.listItem}>Finance Apps</li>
            <li style={styles.listItem}>Social Media Apps</li>
            <li style={styles.listItem}>E-commerce Apps</li>
            <li style={styles.listItem}>Gaming Apps</li>
            <li style={styles.listItem}>Entertainment Apps</li>
          </ul>
        </div>
        <div
          style={styles.pricingPlan}
          onClick={() => handlePlanClick('Pro Plan for Apps')}
        >
          <h3 style={styles.planTitle}>Pro Plan</h3>
          <p style={styles.planPrice}>Price: ₹5,00,000 - ₹15,00,000</p>
          <ul>
            <li style={styles.listItem}>Communication Apps</li>
            <li style={styles.listItem}>Finance Apps</li>
            <li style={styles.listItem}>Travel and Navigation Apps</li>
            <li style={styles.listItem}>News and Magazines Apps</li>
            <li style={styles.listItem}>Photo and Video Apps</li>
            <li style={styles.listItem}>Healthcare Apps</li>
          </ul>
        </div>
      </section>
      <Footer />
    </div>
  );
};

export default Pricing;

