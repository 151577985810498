import React from 'react'
import Navbar from '../Navbar/Navbar'
import Footer from '../Footer/Footer'
import CRImage from '../assets/CarrerSucces.jpg'
import JOImage from '../assets/JoinTeam.jpg'
import AppForm from './ApplicationForm/AppForm'
const Careers = () => {
  return (
    <div>

      <Navbar />
      <section>
             <div class="container-about">
      <div class="text">
      <h1 style={{ color: '#010b96' }}>Our Vision</h1><p style={{ alignItems: 'center', color: '#010b96' }}>At BOAC, Our vision is to be the most trusted and respected business services
provider globally, known for our transformative impact on client
operations and our role as a leader in the private sector employment
market. We strive to continuously evolve and adapt, leveraging
technology and human talent to set new standards in integrated
business services and contribute to India's economic growth and
development.</p>
      <p style={{ alignItems: 'center', color: '#010b96' }}>We believe in fostering a work environment that encourages creativity, collaboration, and continuous learning. Our team is our greatest asset, and we are committed to supporting each member's personal and professional growth.</p>
              </div>
        <div class="image">
        <img src={CRImage} width="500" height="350" alt="" />
        </div>
      
    </div>
        <div class="container text-center">
          <h1 style={{ alignItems: 'center', color: '#010b96' }}>Our Purpose</h1><br />
          <h6 class="py-3" style={{ alignItems: 'center', color: '#010b96' }}>BOAC is all about people. Our single most important purpose is to build meaningful relationships with all our stakeholders, customers, shareholders, employees & partners, while keeping integrity at the core of it all.</h6>
        </div>
        <div class="coinwithhand position-relative text">
          <div class="container py-3 py-lg-4">
            <div class="row align-items-center backgre text">
              <div class="col-12 col-lg-6 ">
                <h1 style={{ color: '#010b96' }}>Current Job Openings</h1><p style={{ alignItems: 'center', color: '#010b96' }}>We are currently looking for talented individuals to fill the following positions:</p>
               <p style={{ color: '#010b96' }}>Our company culture is built on mutual respect, transparency, and the drive to excel. We encourage open communication and believe in recognizing and rewarding hard work. Our employees enjoy a fun and engaging workplace where new ideas are always welcome.</p>
             

              </div>
              {/* <div class="col-12 col-lg-6 backgre"> */}
                <div class='image'>
                  <img  src={JOImage} width="500" height="300" alt="" />
                </div>
              {/* </div> */}
                <AppForm/>
            </div>
          </div>
        </div>
      </section>
 
      <Footer /></div>
  )
}

export default Careers