import React from 'react'
import Navbar from '../Navbar/Navbar'
import Footer from '../Footer/Footer'
import BSImage from '../assets/BusinessSolutionImage.jpg'
const BusinessSolutions = () => {
  return (
    <div><Navbar/>  
  <section>
      <div class="container-about">
      <div class="text">
              <h1 style={{ alignItems: 'center', color: '#010b96'}}>Our Growth</h1>
              <p style={{ alignItems: 'center', color: '#010b96' }}>A comprehensive business solution is an integrated suite of services and technologies designed to address the multifaceted needs of an organization, enhancing operational efficiency, driving innovation, and fostering growth. These solutions encompass a range of components, including advanced software platforms, data analytics tools, cloud computing, and robust cybersecurity measures, which together create a cohesive ecosystem that streamlines workflows and improves decision-making. </p>
              <p style={{ alignItems: 'center', color: '#010b96' }}>By leveraging cutting-edge technologies and best practices, a tailored business solution not only optimizes current processes but also anticipates future challenges, ensuring scalability and adaptability in an ever-evolving market landscape. </p>
              </div>
        <div class="image">
            <img src={BSImage} alt="Description of the image"/>
        </div>
    </div>
  <Footer/>
      </section>
</div>
  )
}

export default BusinessSolutions