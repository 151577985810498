import React, { useState, useEffect } from "react";
import MessangerImage from '../assets/messenger.png';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer'
import {
    FaLock, FaCommentDots, FaPhotoVideo, FaPhone, FaSmile, FaUserCircle, FaPalette, FaUsers,
    FaShieldAlt, FaRobot
} from 'react-icons/fa';
function MessangerInfo() {
    const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);

    useEffect(() => {
        const handleResize = () => setIsMobile(window.innerWidth <= 768);

        window.addEventListener('resize', handleResize);
        return () => window.removeEventListener('resize', handleResize);
    }, []);

    return (
        <div>
            <Navbar />
            <div style={styles.container}>
                <div style={styles.contentContainer}>
                    <div style={styles.content}>
                        <h2 style={styles.heading}>Messenger Demo</h2>
                        <p style={styles.paragraph}>
                            Our Messenger app provides a seamless, secure way to chat with friends and family. It supports one-to-one messaging, group chats, media sharing, and more.
                        </p>
                        <ul style={styles.list}>
                            <li style={styles.listItem}><FaLock style={styles.icon} /> End-to-End Encryption</li>
                            <li style={styles.listItem}><FaCommentDots style={styles.icon} /> Text Messaging</li>
                            <li style={styles.listItem}><FaPhotoVideo style={styles.icon} /> Multimedia Sharing</li>
                            <li style={styles.listItem}><FaPhone style={{ transform: 'rotate(90deg)', ...styles.icon }} /> Voice and Video Calls</li>
                            <li style={styles.listItem}><FaSmile style={styles.icon} /> Message Reactions and Emojis</li>
                            <li style={styles.listItem}><FaShieldAlt style={styles.icon} /> Security Features</li>
                            <li style={styles.listItem}><FaUsers style={styles.icon} /> Group Chats</li>
                            <li style={styles.listItem}><FaUserCircle style={styles.icon} /> User Profiles</li>
                            <li style={styles.listItem}><FaPalette style={styles.icon} /> Custom Themes</li>
                            <li style={styles.listItem}><FaRobot style={styles.icon} /> Bots and Automation</li>
                        </ul>
                    </div>
                    <div style={styles.mobileImageContainer}>
                        <img
                            src={MessangerImage}
                            alt="Mobile Demo"
                            style={isMobile ? styles.mobileImageMobile : styles.mobileImage}
                        />
                    </div>
                </div>
                <div style={styles.additionalContainer}>
                    <div style={styles.row}>
                        <p style={styles.definitionRow}>
                            <strong><FaLock style={styles.icon} /> End-to-End Encryption</strong> Messages are private and only readable by you and the recipient.
                        </p>
                        <p style={styles.definitionRow}>
                            <strong><FaCommentDots style={styles.icon} />Text Messaging:</strong> Instant messaging with real-time communication.
                        </p>
                    </div>
                    <div style={styles.row}>
                        <p style={styles.definitionRow}>
                            <strong><FaPhotoVideo style={styles.icon} />Multimedia Sharing:</strong> Share photos, videos, and media files within chats.
                        </p>
                        <p style={styles.definitionRow}>
                            <strong><FaPhone style={{ transform: 'rotate(90deg)', ...styles.icon }} />Voice and Video Calls:</strong> High-quality voice and video calling features.
                        </p>
                    </div>
                    <div style={styles.row}>
                        <p style={styles.definitionRow}>
                            <strong><FaSmile style={styles.icon} />Message Reactions and Emojis:</strong> React to messages with expressive emojis.
                        </p>
                        <p style={styles.definitionRow}>
                            <strong><FaShieldAlt style={styles.icon} />Security Features:</strong>  Additional protection like two-factor authentication.
                        </p>
                    </div>
                    <div style={styles.row}>
                        <p style={styles.definitionRow}>
                            <strong><FaShieldAlt style={styles.icon} />Security Features:</strong> Implement advanced security protocols to protect user data and transactions.
                        </p>
                        <p style={styles.definitionRow}>
                            <strong><FaUsers style={styles.icon} />Group Chats:</strong> Participate in group conversations for staying connected.
                        </p>
                    </div>
                    <div style={styles.row}>
                        <p style={styles.definitionRow}>
                            <strong><FaPalette style={styles.icon} />Custom Themes:</strong> Personalize the app with custom themes and colors.
                        </p>
                        <p style={styles.definitionRow}>
                            <strong><FaRobot style={styles.icon} />Bots and Automation:</strong> Interactive bots assist with tasks and enhance chats.
                        </p>
                    </div>
                </div>
            </div>
            <Footer />
        </div>
    );
}

const styles = {
    container: {
        display: "flex",
        flexDirection: "column",
        padding: "20px",
        width: "100%", // Full width of the viewport
        margin: "0 auto", // Center-aligned
        backgroundColor: "#fff",
    },
    contentContainer: {
        display: "flex",
        flexDirection: "row",
        justifyContent: "space-between",
        alignItems: "center",
        marginBottom: "20px",
    },
    content: {
        flex: 1,
        paddingRight: "20px",
    },
    heading: {
        color: '#010b96',
        fontWeight: 700,
    },
    paragraph: {
        color: '#010b96',
    },
    list: {
        listStyleType: "none",
        padding: 0,
    },
    listItem: {
        display: "flex",
        alignItems: "center",
        marginBottom: "10px",
        color: '#010b96',
        fontWeight: 700,
    },
    icon: {
        marginRight: "10px",
        fontSize: "1.2em",
        color: '#010b96',
    },
    mobileImageContainer: {
        flex: 1,
        display: "flex",
        justifyContent: "center",
    },
    mobileImage: {
        maxWidth: "40%",
        height: "auto",
        borderRadius: "10px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    },
    mobileImageMobile: {
        maxWidth: "100%",
        height: "auto",
        borderRadius: "10px",
        boxShadow: "0 4px 8px rgba(0, 0, 0, 0.1)",
    },
    additionalContainer: {
        width: "100%",
        backgroundColor: "#fff",
    },
    row: {
        display: "flex",
        flexDirection: "row", // Changed to column for better readability
        marginBottom: "20px",
    },
    definitionRow: {
        flex: 1,
        padding: "15px",
        margin: "5px",
        backgroundColor: "#f0f4ff",
        borderLeft: "5px solid #010b96",
        borderRadius: "5px",
        color: '#010b96',
        fontWeight: 400,
        lineHeight: "1.5",
    },
};
export default MessangerInfo;
