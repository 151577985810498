import React from 'react'
import { useNavigate } from 'react-router-dom'
import './CardAni.css';
import backgroundImg from '../assets/back.jpg'

const CardAni = () => {
  const navigate = useNavigate()
  return (
    <div class="box-container">
    <div class="box-item">
      <div class="flip-box">
        <div class="flip-box-front text-center" style={{backgroundImage: `url(${backgroundImg})`}}>
          <div class="inner color-white">
            <h3 class="flip-box-header">IT Solutions & Services</h3>
            <img src="https://s25.postimg.cc/65hsttv9b/cta-arrow.png" alt="" class="flip-box-img"/>
          </div>
        </div>
        <div class="flip-box-back text-center" style={{backgroundImage: `url(${backgroundImg})`}}>
          <div class="inner color-white">
            <h3 class="flip-box-header"></h3>
            <p>Click on know more about IT Services</p>
            <button class="flip-box-button" onClick={() => {
              navigate(`/itservices`)
            }}>Learn More</button>
          </div>
        </div>
      </div>
    </div>
    <div class="box-item">
      <div class="flip-box">
        <div class="flip-box-front text-center" style={{backgroundImage: `url(${backgroundImg})`}}>
          <div class="inner color-white">
            <h3 class="flip-box-header">Financial Services</h3>
            <img src="https://s25.postimg.cc/65hsttv9b/cta-arrow.png" alt="" class="flip-box-img"/>
          </div>
        </div>
        <div class="flip-box-back text-center" style={{backgroundImage: `url(${backgroundImg})`}}>
          <div class="inner color-white">
          <p>Click on know more about Financial Services</p>
            <button class="flip-box-button" onClick={() => {
              navigate(`/finance`)
            }}>Learn More</button>
          </div>
        </div>
      </div>
    </div>
    <div class="box-item">
      <div class="flip-box">
        <div class="flip-box-front text-center" style={{backgroundImage: `url(${backgroundImg})`}}>
          <div class="inner color-white">
            <h3 class="flip-box-header">TeleCommunication Services</h3>
            <img src="https://s25.postimg.cc/65hsttv9b/cta-arrow.png" alt="" class="flip-box-img"/>
          </div>
        </div>
        <div class="flip-box-back text-center" style={{backgroundImage: `url(${backgroundImg})`}}>
          <div class="inner color-white">
            <h3 class="flip-box-header"></h3>
            <p>Click on know more about TeleCom Services</p>
            <button class="flip-box-button" onClick={() => {
              navigate(`/telecommunication`)
            }}>Learn More</button>
          </div>
        </div>
      </div>
    </div>
    <div class="box-item">
      <div class="flip-box">
        <div class="flip-box-front text-center" style={{backgroundImage: `url(${backgroundImg})`}}>
          <div class="inner color-white">
            <h3 class="flip-box-header">OutSourcing</h3>
            <img src="https://s25.postimg.cc/65hsttv9b/cta-arrow.png" alt="" class="flip-box-img"/>
          </div>
        </div>
        <div class="flip-box-back text-center" style={{backgroundImage: `url(${backgroundImg})`}}>
          <div class="inner color-white">
            <h3 class="flip-box-header"></h3>
            <p>Click on know more about OutSourcing Services</p>
            <button class="flip-box-button" onClick={() => {
              navigate(`/outsourcing`)
            }}>Learn More</button>
          </div>
        </div>
      </div>
    </div>
  </div>
  

  )
}

export default CardAni