import React, { useEffect } from 'react';
import Navbar from '../Navbar/Navbar';
import telecomImage from '../assets/money-case.jpg';
import Footer from '../Footer/Footer';
import PLImage from '../assets/Personal-Loan.jpg';
import CLImage from '../assets/Consumer-Loan.jpg';
import HLImage from '../assets/home.jpg';
import BLImage from '../assets/Business-Loan.jpg';
import VLImage from '../assets/Vehicle.jpg';
import CCLImage from '../assets/Credit-Card-Loan.jpg';
import './Telecommunication.css';
import FormModel from '../Model/FormModel';

const Finance = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Navbar />

      <section>
        <div className="coinwithhand position-relative text">
          <div className="container py-3 py-lg-4">
            <div className="row align-items-center backgre">
              <div className="col-12 col-lg-6">
                <h1 style={{ color: '#010b96' }}>Finance</h1>
                <p style={{ color: '#010b96' }}>
                  BOAC Private Ltd. is one of India’s leading Business services Provider company that Provides Financial supports to Precious All Clients & Customer with the supports of Nationalised Banks in Process of DSA (Direct Selling Agent).
                </p>
                <p style={{ color: '#010b96' }}>
                  BOAC Private Ltd. offers a wide range of loan products at attractive interest rates Approved by RBI.
                </p>
                <p className="py-3" style={{ color: '#010b96' }}>
                  Our clients outsource our services for their requirements such as the rapid growth in telecom services through cloud-based services. The number of alarm points that must be kept track of is scaling to the millions. This is making the older methods of monitoring and trouble-shooting obsolete. With “virtualized” networks coming online, businesses need better assurance capabilities.
                </p>
              </div>
              <div className="col-12 col-lg-6 backgre">
                <div style={{ textAlign: 'center' }}>
                  <img 
                    src={telecomImage} 
                    alt="" 
                    style={{ 
                      width: '100%', 
                      maxWidth: '500px', 
                      height: 'auto' 
                    }} 
                  />
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className="container text-center">
          <h1 style={{ color: '#010b96' }}>FINANCE SERVICES</h1><br />
          <div className="row" style={{ alignItems: 'center', justifyContent: 'center' }}>
            <div className="col-sm-4" style={{ color: '#010b96' }}>
              <img src={BLImage} className="img-responsive" style={{ width: "100%",height: '150px'}} alt="Image" />
              <h5>Business Loan</h5>
            </div>
            <div className="col-sm-4" style={{ color: '#010b96' }}>
              <img src={PLImage} className="img-responsive" style={{ width: "100%", height: '150px' }} alt="Image" />
              <h5>Personal Loan</h5>
            </div>
            <div className="col-sm-4" style={{ color: '#010b96' }}>
              <img src={HLImage} className="img-responsive" style={{ width: "100%", height: '150px' }} alt="Image" />
              <h5>Home Loans</h5>
            </div>
            <div className="col-sm-4" style={{ color: '#010b96' }}>
              <img src={VLImage} className="img-responsive" style={{ width: "100%", height: '150px' }} alt="Image" />
              <h5>Vehicle Loan</h5>
            </div>
            <div className="col-sm-4" style={{ color: '#010b96' }}>
              <img src={CLImage} className="img-responsive" style={{ width: "100%",height: '150px' }} alt="Image" />
              <h5>Consumer Loan</h5>
            </div>
            <div className="col-sm-4" style={{ color: '#010b96' }}>
              <img src={CCLImage} className="img-responsive" style={{ width: "100%", height: '150px' }} alt="Image" />
              <h5>Credit Cards</h5>
            </div>
          </div>
          <div>
            <h1 style={{ color: '#010b96' }}>Apply Online for BOAC Finance now!</h1>
            <FormModel />
            <br/>
          </div>
        </div>
      </section>

      <Footer />
    </div>
  );
};

export default Finance;
