import React from 'react';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import BlogImage from '../assets/BlogImage.jpg';

const Blogs = () => {
  return (
    <div>
      <Navbar />
      <section>
        <div className="container-about">
          <div className="text" style={{ textAlign: 'left' }}>
            <h1 style={{ color: '#010b96' }}>Blogs</h1>
            <p style={{ color: '#010b96' }}>
              In today's fast-paced digital landscape, businesses require robust and reliable IT solutions to stay competitive and drive innovation. Our company specializes in delivering comprehensive IT services tailored to meet the unique needs of each client.
              Our passion for delivering exceptional services, augmented by proprietary digital platforms, has strongly established our credentials as India’s largest employer in the private sector and the biggest integrated business services provider in the country.
            </p>
            <p style={{ color: '#010b96' }}>
              From cloud computing and cybersecurity to network infrastructure and software development, we provide end-to-end solutions that enhance efficiency and foster growth. Our team of seasoned professionals leverages the latest technologies and industry best practices to deliver scalable and secure IT environments. By partnering with us, businesses gain access to cutting-edge solutions that streamline operations, protect critical data, and support strategic objectives. Whether you're a startup looking to build a solid IT foundation or an established enterprise seeking to optimize your technology investments, we are committed to delivering exceptional service and unparalleled expertise every step of the way.
            </p>
          </div>
          <div className="image" style={{ textAlign: 'left' }}>
            <img src={BlogImage} alt="Description of the image" />
            <p className="py-3" style={{ color: '#010b96', marginTop: '20px', maxWidth: '800px', margin: '0 auto' }}>
              A core value driving our business is constantly making the workforce more productive. Our business strategy is aligned with this, including training and skill development for better employability, helping job seekers easily find employment opportunities, digitizing workflows, and providing social security benefits to a wider employable population.
            </p>
          </div>
          <div className="container text-center">
            <h1 style={{ color: '#010b96' }}>Event Coverage</h1><br />
            <h6 className="py-3" style={{ color: '#010b96' }}>
              Reports or summaries of industry events, conferences, webinars, or workshops, highlighting key takeaways and insights.
            </h6>
          </div>
        </div>
        <Footer />
      </section>
    </div>
  );
};

export default Blogs;
