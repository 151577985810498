import React from 'react';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import image from '../assets/PrivacyPolicy.jpg';
import './PrivacyPolicy.css'; // Assuming you have this CSS file for the styles
const PrivacyPolicy = () => {
  return (
    <div>
      <Navbar />
      <div
        style={{
          color: '#ffffff',
          fontWeight: 'bold',
          backgroundImage: `url(${image})`,
          backgroundSize: 'cover',
          backgroundPosition: 'center',
          backgroundRepeat: 'no-repeat',
          // minHeight: '20vh',
          height:400,
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'center',
          paddingTop: '20px', // Add some padding to move the content down a bit
          animation: 'fadeIn 2s ease-in-out',
          textAlign: 'center', // Center-align text
        }}
      >
        <h2
          style={{
            color: "#FFF",
            fontWeight: 700,
            fontSize: '2.5rem', // Increase font size for the main title
            margin: '0', 
            paddingBottom:300,
            animation: 'fadeInDown 1s ease-in-out',
             fontFamily:'sans-serif',
             textShadow: '2px 2px 8px rgba(0, 0, 0, 0.5)'
          }}
        >
          Privacy and Data Protection at Bharat OnAir Corporate
        </h2>
      </div>
      <div style={{ padding: '20px', color: '#010b96' }}>
        <p>Bharat OnAir Corporate (collectively, “BOAC”, “us” or “we”) understand that your privacy is important to you. We are committed to respecting your privacy and protecting your personal data, which is any information that is capable of identifying you as an individual person. This Privacy Policy describes how we handle and protect your personal data in connection with BOAC's business activities, like conferences and events, and client services, and on websites, applications, and communications that post a link to this Privacy Policy (collectively, “the Sites”), in our capacity as data controllers. Please see our Terms of use for more information about our online terms and policies in general.</p>

        <h5 style={{ color: '#010b96', fontWeight: 'bold' }}>Information we collect</h5>
        <p>BOAC collects personal data in the course of its business activities, including in connection with some client services. We also collect personal data on certain areas of the Sites when you register for or apply for jobs through the BOAC Careers Portal, create a user profile, register for BOAC newsletters and alerts, participate in our alumni activities through our Alumni Center Website, sign up for conferences and events, and/or participate in public user posting areas (such as bulletin boards, discussion forums and surveys). The personal data collected varies and may include information that you provide, such as your name, mailing address, e-mail address, telephone number, fax number, and background information required to apply for a job.</p>
        <p>We may also automatically collect information about the devices you use to interact with our Sites. The information we automatically collect may include IP address, device identifier, web browser, and browsing information collected through cookies, web beacons, pixels, clear gifs, and other similar technologies (collectively “Cookies and Other Tracking Technologies”) on our Sites. We may also automatically collect information about how you use the Sites, such as what you have searched for and viewed on the Sites. The information automatically collected may be associated with a unique identifier as well as with any other personal data you have provided.</p>
        <p>BOAC may also collect personal data about you from third parties, such as data brokers or aggregators, in the course of its business activities including in connection with some client services. This includes demographic, professional and other information that is publicly available online, including information you choose to make public through social media platforms and other public online forums. We may combine this data with existing information we have about you or use it independently, for the purposes of our benchmarking and data analytics activities (e.g., analysis of recruiting practices across an industry, detecting fraud patterns in connection with financial transactions, foot traffic in retail environments). We will always seek to confirm that the third party has provided transparent information about its use of this data, including its disclosure to third parties like BOAC, in compliance with applicable law.</p>

        <h5 style={{ color: '#010b96', fontWeight: 'bold' }}>Use of information</h5>
        <p>BOAC uses your personal data to fulfill your requests for information, process your requests to participate in conferences and events, personalize content that you view or receive on the Sites, evaluate and improve our services, distribute newsletters and alerts to you, recruit and evaluate job applicants, analyze the Sites’ performance and functioning, publish your letters to the editor or comments, prevent fraud, enforce our terms of use, comply with all applicable laws and corporate reporting obligations, enforce BOAC’s agreements, and accomplish other purposes you may initiate or request. In some situations, the collection of personal data may be required for the operation of the Sites, or we may use it in the course of our business activities, including in connection with some client services, for example, to provide certain services or products such as our benchmarking products. We may combine and/or analyze personal data to evaluate and offer content and services most relevant to you. We may keep any of your personal data on file and use it to contact you.</p>

        <p>BOAC and its service providers may use first and third party Cookies and Other Tracking Technologies, including web beacons, to manage our Sites and our services and collect analytics about how you use them. BOAC and its service providers may collect information about whether you open or click any links in the knowledge, research or event communications that we send to you. The information provided throughout this Privacy Policy about cookies also applies to these other tracking technologies. Please refer to our Cookie Policy for more details regarding our use of Cookies and Other Tracking Technologies.</p>

        <p>Our Sites do not track you by collecting personal data about your online activities over time and across third party websites or online services. Accordingly, we do not alter our data collection and use practices in response to “do not track” signals transmitted from web browsers.</p>

        <h5 style={{ color: '#010b96', fontWeight: 'bold' }}>Use of information collected via mobile devices</h5>
        <p>In connection with our mobile applications, BOAC may use third party service providers to analyze user activity to fix errors, monitor usage, and improve the performance of the mobile applications. For example, BOAC receives reports on some of our mobile applications’ aggregate usage and browsing patterns, including information about the type of device used, articles accessed, and other events occurring within our apps. BOAC also receives reports on certain errors occurring within mobile applications.</p>

        <p>In addition, our BOAC Insights app offers a personalized list of recommendations (called “Insights for you”) directing you to BOAC content that we think you will find interesting. Recommendations are based solely on what you have viewed in the Insights app. A unique user identifier that is generated by the app helps personalize your app experience and tracks the articles that you read in the app. We do not share your viewing history or trends through the Insights app with other users or any external third parties (i.e., persons or entities that are not affiliates or third party service providers of BOAC).</p>

        <h5 style={{ color: '#010b96', fontWeight: 'bold' }}>The legal basis by which we process your personal data</h5>
        <p>Our processing of your personal data for the purposes mentioned above is based:</p>
        <ul style={{ listStyleType: 'disc', color: '#010b96' }}>
          <li>in part, on our legitimate interests in promoting and protecting BOAC, building and maintaining relationships, recruitment, and providing our services;</li>
          <li>in part, on your consent, for example, if you create a user profile, or register for our newsletters or alerts, or for business purposes that support web operations such as understanding and enhancing the quality of your experience on our websites;</li>
          <li>in part, to comply with the law, when certain information is necessary to satisfy our legal or regulatory obligations.</li>
        </ul>

        <h5 style={{ color: '#010b96', fontWeight: 'bold' }}>Disclosure of personal data: data recipients and international data transfers</h5>
        <p>Personal data collected in the course of BOAC business activities, including in connection with some client services, as well as on the Sites may be transferred from time to time to BOAC subsidiaries and affiliates and their personnel across our global organization, as well as to our third party service providers located throughout the world, including in countries where the local law may grant you fewer rights than you have in your own country. Additionally, the Sites may be viewed and hosted by BOAC and our third party service providers anywhere in the world. Where required by law, we have put in place legal mechanisms designed to ensure adequate data protection of your personal data that is processed by BOAC subsidiaries, affiliates, and third party service providers, including the transfer of your personal data to countries other than the one in which you reside. If you would like more information about these legal mechanisms, which may include the EU’s Standard Contractual Clauses, please contact us at the address below. By using any of the Sites and providing information on any of them, you voluntarily consent to such trans-border transfer and hosting of such information.</p>

        <p>BOAC will not intentionally disclose or transfer (and will take reasonable steps to prevent the unauthorized or accidental disclosure of) your personal data to third parties without your consent or as otherwise permitted by law, whether for such third parties’ own marketing purposes or otherwise, except as follows. BOAC may provide access to your personal data to third party service providers engaged by BOAC to provide services related to the Sites as well as related to BOAC’s business activities, including in connection with some client services, in the manner agreed upon in our client services agreements. We maintain processes designed to ensure that any processing of personal data by third party service providers is consistent with this Privacy Policy and protects the confidentiality, availability, and integrity of your personal data.</p>

        <p>We also may share your personal data with third party service providers who perform services and functions on our behalf to support our interactions with you, including, for example, processing recruitment materials, administering surveys or contests, or communicating with you.</p>

        <p>In addition, we may disclose information about you:</p>
        <ul style={{ listStyleType: 'disc', color: '#010b96' }}>
          <li>If we are required to do so by law or legal process;</li>
          <li>To law enforcement authorities or other government officials;</li>
          <li>When we believe disclosure is necessary or appropriate to prevent physical harm or financial loss or in connection with an investigation of suspected or actual illegal activity;</li>
          <li>If disclosure is necessary to protect the vital interests of a person;</li>
          <li>To enforce our Terms of use;</li>
          <li>To protect our property, services and legal rights;</li>
          <li>To prevent fraud against BOAC, our affiliates, business partners, or authorized persons, in relation to your personal data;</li>
          <li>To support auditing, compliance, and corporate governance functions; or</li>
          <li>To comply with any and all applicable laws.</li>
        </ul>
      </div>
      <Footer />
    </div>
  );
}

export default PrivacyPolicy;
