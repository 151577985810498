import React from 'react';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import MessangerIntro from '../video/MessangerSlide.mp4'; 
import MerchantInfo from '../video/MerchantSlide.mp4';
import BoacPay from '../video/BoacPay.mp4';
import './OurProducts.css';
const CustomPrevArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} slick-prev`}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
    >
      <i className="slick-prev-icon" />
    </div>
  );
};

const CustomNextArrow = (props) => {
  const { className, style, onClick } = props;
  return (
    <div
      className={`${className} slick-next`}
      style={{ ...style, display: 'block' }}
      onClick={onClick}
    >
      <i className="slick-next-icon" />
    </div>
  );
};


const OurProducts = () => {
  const settings = {
    dots: true,
    infinite: true,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
    prevArrow: <CustomPrevArrow />,
    nextArrow: <CustomNextArrow />,
  };

  return (
    <div style={{ width: '80%', margin: '0 auto' }}>
      <h1 style={{ color: '#010b96', alignItems: 'center', textAlign: 'center' }}>OUR PRODUCTS</h1>
      <Slider {...settings}>
        <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <video
            className="d-block w-100"
            src={MessangerIntro}
            width={800}
            height={350}
            autoPlay
            muted
            loop
          />
        </div>
        <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <video
            className="d-block w-100"
            src={MerchantInfo}
            width={800}
            height={350}
            autoPlay
            muted
            loop
          />
        </div>
        <div style={{ position: 'relative', display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
          <video
            className="d-block w-100"
            src={BoacPay}
            width={800}
            height={350}
            autoPlay
            muted
            loop
          />
        </div>
      </Slider>
    </div>
  );
};

export default OurProducts;
