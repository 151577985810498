import React from 'react';
import { FaArrowRight } from 'react-icons/fa';
import { Link } from 'react-router-dom'; // Import Link from react-router-dom
import '../Card/Card.css';
import image1 from '../assets/hr1.jpg';
import image2 from '../assets/logistics.jpg';
import image3 from '../assets/contract.jpg';
import image4 from '../assets/security.jpg';
import image5 from '../assets/ITOutsourcing.jpg';

function MyCard() {
  return (
    <div className="container news text-center py-3 py-lg-5">
      <h3 style={{ color: '#1d4ed8',fontFamily: 'fantasy' }}>Bharat On Air Corporate</h3>
      <h5 className="pb-md-3" style={{ color: '#010b96', fontWeight: '700', }}>(BOAC)</h5>

      {/* Learn More Button */}
      <div className="learn-more">
        <a href="#learn-more">Explore Our Outsourcing Services</a>
      </div>

      <div className="row justify-content-center">
        <div className="col-12 col-sm-6 col-md-4 mb-3 mb-md-0">
          <Link to="/itservices"> {/* Add Link for navigation */}
            <div className="card" style={{ backgroundImage: `url(${image1})` }}>
              <div className="card-body">
                <FaArrowRight />
              </div>
            </div>
          </Link>
        </div>
        <div className="col-12 col-sm-6 col-md-4 mb-3 mb-md-0">
          <Link to="/transport"> {/* Add Link for navigation */}
            <div className="card" style={{ backgroundImage: `url(${image2})` }}>
              <div className="card-body">
                <FaArrowRight />
              </div>
            </div>
          </Link>
        </div>
        <div className="col-12 col-sm-6 col-md-4 mb-3 mb-md-0">
          <Link to="/contractLabour"> {/* Add Link for navigation */}
            <div className="card" style={{ backgroundImage: `url(${image3})` }}>
              <div className="card-body">
                <FaArrowRight />
              </div>
            </div>
          </Link>
        </div>
        <div className="col-12 col-sm-6 col-md-4 mb-3 mb-md-0">
          <Link to="/securityService"> {/* Add Link for navigation */}
            <div className="card" style={{ backgroundImage: `url(${image4})` }}>
              <div className="card-body">
                <FaArrowRight />
              </div>
            </div>
          </Link>
        </div>
        <div className="col-12 col-sm-6 col-md-4 mb-3 mb-md-0">
          <Link to="/hrConsultant"> {/* Add Link for navigation */}
            <div className="card" style={{ backgroundImage: `url(${image5})` }}>
              <div className="card-body">
                <FaArrowRight />
              </div>
            </div>
          </Link>
        </div>
      </div>
    </div>
  );
}

export default MyCard;
