import React from 'react';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import BoacPay from '../video/boac.mp4'; // Import your MP4 file
import { CiMail } from "react-icons/ci";
import { FaPhone } from "react-icons/fa6";

const styles = {
  headerRow: {
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    marginBottom: '20px',
  },
  contactInfo: {
    textAlign: 'right',
    fontSize: '0.9em',
    color: '#010b96',
    paddingBottom: '2px',
  },
  '@media (max-width: 768px)': {
    headerRow: {
      flexDirection: 'column',
      alignItems: 'flex-start',
    },
    contactInfo: {
      textAlign: 'left',
      marginTop: '10px',
    },
  },
};

const TS = () => {
  return (
    <div>
      <Navbar />
      <div style={{ display: 'flex', flexDirection: 'column', padding: '20px' }}>
        <div style={styles.headerRow}>
          <h2 style={{ color: '#010b96', fontSize: '2.0rem', fontWeight: 700 }}  class="text">
            Trust and Safety Features
          </h2>
          <div style={styles.contactInfo}>
            <p style={{ alignItems: 'center', color: '#010b96' }}>
              <CiMail />
              <a href="mailto:support@bharatonair.com" style={{ color: '#010b96' }}> support@bharatonair.com</a>
            </p>
            <p><FaPhone /> Phone: +91-8925930571</p>
          </div>
        </div>
        <div style={{ maxWidth: '100%', width: '100%' }}>
          <video
            className="d-block w-100"
            src={BoacPay}
            width="100%"
            height="auto"
            autoPlay
            muted
            loop
            style={{ borderRadius: '8px', boxShadow: '0 4px 8px rgba(0,0,0,0.2)' }}
          />
          <p style={{ fontSize: '1rem', color: '#010b96', paddingTop: 10 }}>
            At Boac Messenger, your safety and privacy are our top priorities. Our platform is equipped with cutting-edge security measures to protect your data and ensure a safe communication experience.
          </p>
        </div>

        <h3 style={{ color: '#010b96', fontSize: '1.25rem', fontWeight: 700 }}>
          Key Features:
        </h3>
        <ul style={{ fontSize: '1rem', color: '#010b96' }}>
          <li>End-to-End Encryption</li>
          <li>Two-Factor Authentication</li>
          <li>Data Anonymization</li>
          <li>Real-Time Threat Detection</li>
          <li>Regular Security Audits</li>
        </ul>
        <p style={{ fontSize: '1rem', color: '#010b96' }}>
          We are committed to maintaining the highest standards of security, so you can communicate with confidence.
        </p>

        <div style={{ padding: '20px', backgroundColor: '#f8f9fa', maxWidth: '800px', width: '100%' }}>
  <h3 style={{ color: '#010b96', fontSize: '1.25rem', fontWeight: 700 }}>
    Data Protection
  </h3>
  <p style={{ fontSize: '1rem', color: '#010b96' }}>
    Your personal information is encrypted and stored securely. We comply with the highest industry standards and legal requirements to protect your data.
  </p>

  <h3 style={{ color: '#010b96', fontSize: '1.25rem', fontWeight: 700 }}>
    Secure Transactions
  </h3>
  <p style={{ fontSize: '1rem', color: '#010b96' }}>
    All payments and financial data are handled with advanced security measures, ensuring that your transactions are safe and secure.
  </p>

  <h3 style={{ color: '#010b96', fontSize: '1.25rem', fontWeight: 700 }}>
    User Verification
  </h3>
  <p style={{ fontSize: '1rem', color: '#010b96' }}>
    We implement robust verification processes to confirm the identity of our users, minimizing the risk of fraudulent activity and ensuring a community of genuine interactions.
  </p>

  <h3 style={{ color: '#010b96', fontSize: '1.25rem', fontWeight: 700 }}>
    Reporting and Moderation
  </h3>
  <p style={{ fontSize: '1rem', color: '#010b96' }}>
    Our team is dedicated to maintaining a respectful community. You can report any suspicious or inappropriate behavior, and our moderation team will take swift action.
  </p>

  <h3 style={{ color: '#010b96', fontSize: '1.25rem', fontWeight: 700 }}>
    Privacy Controls
  </h3>
  <p style={{ fontSize: '1rem', color: '#010b96' }}>
    You have control over your privacy settings, allowing you to manage what information is shared and with whom.
  </p>

  <h3 style={{ color: '#010b96', fontSize: '1.25rem', fontWeight: 700 }}>
    Continuous Monitoring
  </h3>
  <p style={{ fontSize: '1rem', color: '#010b96' }}>
    We continuously monitor our platform for any potential security threats or breaches. Our systems are regularly updated to defend against new risks.
  </p>

  <h3 style={{ color: '#010b96', fontSize: '1.25rem', fontWeight: 700 }}>
    Community Guidelines
  </h3>
  <p style={{ fontSize: '1rem', color: '#010b96' }}>
    Our community guidelines are designed to promote positive and respectful interactions. We take violations of these guidelines seriously to maintain a safe environment for everyone.
  </p>

  <h3 style={{ color: '#010b96', fontSize: '1.25rem', fontWeight: 700 }}>
    Education and Awareness
  </h3>
  <p style={{ fontSize: '1rem', color: '#010b96' }}>
    We provide resources to educate our users on best practices for online safety and privacy, empowering you to make informed decisions.
  </p>

  <h3 style={{ color: '#010b96', fontSize: '1.25rem', fontWeight: 700 }}>
    Responsive Support
  </h3>
  <p style={{ fontSize: '1rem', color: '#010b96' }}>
    Our support team is here to help. If you encounter any issues or have concerns about your safety, our team is available 24/7 to assist you.
  </p>
</div>
      </div>
      <Footer />
    </div>
  );
};

export default TS;
