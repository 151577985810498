import React from 'react'

const FormModel = () => {
  return (
    <div>
         <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#myModal">
   Click Me
  </button>
<div class="modal" id="myModal">
  <div class="modal-dialog">
    <div class="modal-content">
      <div class="modal-header">
        <h4 class="modal-title">Application Form</h4>
        <button type="button" class="btn-close" data-bs-dismiss="modal"></button>
      </div>


     
<div class="container mt-3">
  <h2>Enter You Details</h2>
  <form action="/action_page.php">
  <div class="mb-3 mt-3">
      {/* <label for="name">Name:</label> */}
      <input type="name" class="form-control" id="name" placeholder="Enter name" name="name"/>
    </div>
    <div class="mb-3 mt-3">
      {/* <label for="phoneNumber">Phone Number:</label> */}
      <input type="phoneNumber" class="form-control" id="phoneNumber" placeholder="Enter phoneNumber" name="phoneNumber"/>
    </div>
    <div class="mb-3">
      {/* <label for="email">Email:</label> */}
      <input type="email" class="form-control" id="email" placeholder="Enter email" name="email"/>
    </div>
    <div class="mb-3">
      {/* <label for="pwd">Password:</label> */}
      <input type="password" class="form-control" id="pwd" placeholder="Enter password" name="pswd"/>
    </div>
    <div class="mb-3">
      {/* <label for="location">City:</label> */}
      <input type="location" class="form-control" id="location" placeholder="Enter location" name="location"/>
    </div>
    <div class="mb-3">
      {/* <label for="state">State:</label> */}
      <input type="state" class="form-control" id="state" placeholder="Enter state" name="state"/>
    </div>
    <div class="form-check mb-3">
      <label class="form-check-label">
        <input class="form-check-input" type="checkbox" name="remember"/> Remember me
      </label>
    </div>
    <button type="submit" class="btn btn-primary">Submit</button>
  </form>
</div>
      <div class="modal-footer">
        <button type="button" class="btn btn-danger" data-bs-dismiss="modal">Close</button>
      </div>

    </div>
  </div>
</div>
  </div>
  )
}

export default FormModel