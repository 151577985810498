import React from 'react';
import Navbar from '../Navbar/Navbar';
import Form from '../Form/Form';
import Footer from '../Footer/Footer'
import { FaPhone } from "react-icons/fa6";
import { CiMail } from "react-icons/ci";
import { MdLocationPin } from "react-icons/md";

const Conatact = () => {
  return (
    <div>
      <Navbar />
      {/* <section>
       
        <div class="coinwithhand position-relative">
          <div class="container py-2 py-lg-4">
            <div class="row align-items-center backgre">
              <div class="col-12 col-lg-6">
              <h1 style={{ alignItems: 'center', color: '#010b96'}}>Contact Us</h1>
                <p style={{ alignItems: 'center', color: '#010b96'}}>We value your feedback and are here to assist you with any questions or concerns you may have. Please reach out to us through any of the following methods, and our team will get back to you as soon as possible.</p>
                
                <h4 style={{ alignItems: 'center', color: '#010b96'}}>Customer Support</h4>
                <p style={{ alignItems: 'center', color: '#010b96'}}>For customer service inquiries, please contact us at:</p>
              </div>
              <div class="col-12 col-lg-6 backgre" >
             

           
            
            
         
              </div>
            </div>
          </div>
        </div>
  
      </section> */}

<section>
             <div class="container-about">
      <div class="text">
      <h1 style={{ alignItems: 'center', color: '#010b96'}}>Contact Us</h1>
      <p style={{ alignItems: 'center', color: '#010b96'}}>We value your feedback and are here to assist you with any questions or concerns you may have. Please reach out to us through any of the following methods, and our team will get back to you as soon as possible.</p>
      <h4 style={{ alignItems: 'center', color: '#010b96'}}>Customer Support</h4>
                <p style={{ alignItems: 'center', color: '#010b96'}}>For customer service inquiries, please contact us at:</p>
              </div>
        <div >
        <h6 style={{ alignItems: 'center', color: '#010b96'}} className='text fw-bold mb-4'>Location</h6>
        <p style={{ alignItems: 'center', color: '#010b96'}}>
              <MdLocationPin/>
              Pulsebay,No:64 Subbiah Salai ,2nd Floor Pondicherry-605001
              </p>
        <p style={{ alignItems: 'center', color: '#010b96'}}>
              <CiMail/>
              <a  href="mailto:admin@bharatonair.com" style={{color: '#010b96'}}> admin@bharatonair.com</a>
              
              </p>
              <p style={{ alignItems: 'center', color: '#010b96'}}>
              <CiMail/>
              <a  href="mailto:support@bharatonair.com"style={{color: '#010b96'}}> support@bharatonair.com</a> 
              </p>
              
        <p style={{ alignItems: 'center', color: '#010b96'}}>
              <FaPhone />8925930570
              </p>
        </div>
        <div>
        <Form/>
        </div>
    
    </div>

      </section>
   
      <Footer/>
</div>
  );
};

export default Conatact;