import React from 'react';
import './Footer.css'
import { MDBFooter, MDBContainer, MDBRow, MDBCol, MDBIcon } from 'mdb-react-ui-kit';
import { FaPhone } from "react-icons/fa6";
import { CiMail } from "react-icons/ci";
import { MdLocationPin } from "react-icons/md";
import { FaSquareInstagram } from "react-icons/fa6";
import { GrLinkedin } from "react-icons/gr";
import { FaTwitter } from "react-icons/fa";
import { FaFacebookSquare } from "react-icons/fa";
export default function App() {
  return (
    <MDBFooter style={{backgroundColor:'#000d32'}} className='text-center text-lg-start text-muted'>
      <section className='d-flex justify-content-center justify-content-lg-between p-4 border-bottom'>
        <div className='me-5 d-none d-lg-block'>
          <span>Get connected with us on social networks:</span>
        </div>

        <div>
          <a href='https://www.facebook.com/profile.php?id=61561502594807' className='me-4 text-reset'>
            <FaFacebookSquare style={{ color:' #fff'}}/>
          </a>
          <a href='https://x.com/BOACpvt' className='me-4 text-reset'>
            <FaTwitter style={{ color:' #fff'}}/>
          </a>
          <a href='https://www.instagram.com/boac_groups/' className='me-4 text-reset'>
          <FaSquareInstagram style={{ color:' #fff'}}/>
          </a>
          <a href='https://www.linkedin.com/company/bharat-on-air-corporate-p-limited/' className='me-4 text-reset'>
            <GrLinkedin style={{ color:' #fff'}}/>
          </a>
        </div>
      </section>

      <section className='footer'>
        <MDBContainer className='text-center text-md-start mt-5'>
          <MDBRow className='mt-3'>
            <MDBCol md="3" lg="4" xl="3" className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>
                <MDBIcon icon="gem" className="me-3" />
                Bharat OnAir Corporate
              </h6>
              <p>
              Bharat On Air Corporate (P) Limited (BOAC) is India’s leading business services provider
              </p>
            </MDBCol>

            <MDBCol md="2" lg="2" xl="2" className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Products</h6>
              <p>
                <a to='/' className='text-reset'>
                Home
                </a>
              </p>
              <p>
                <a href='/about' className='text-reset'>
                About
                </a>
              </p>
              <p>
                <a href='/careers' className='text-reset'>
                Careers
                </a>
              </p>
              <p>
                <a href='/blogs' className='text-reset'>
                Blogs
                </a>
              </p>
              
            </MDBCol>
            <MDBCol md="3" lg="2" xl="2" className='mx-auto mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Services</h6>
              <p>
                <a href='/itservices' className='text-reset'>
                IT Solutions
                </a>
              </p>
              <p>
                <a href='/finance' className='text-reset'>
                Finance
                </a>
              </p>
              <p>
                <a href='/telecommunication' className='text-reset'>
                Telecommunication
                </a>
              </p>
              <p>
                <a href='/outsourcing' className='text-reset'>
                OutSourcing
                </a>
              </p>
            </MDBCol>
            <MDBCol md="4" lg="3" xl="3" className='mx-auto mb-md-0 mb-4'>
              <h6 className='text-uppercase fw-bold mb-4'>Contact</h6>
              <p>
              <MdLocationPin/> Pulsebay,No:64 Subbiah Salai ,2nd Floor Pondicherry-605001
              </p>
              <p>
              <CiMail/>
              <a  href="mailto:admin@bharatonair.com"> admin@bharatonair.com</a> 
              </p>
              <p>
              <FaPhone />+91 8925930570
              </p>
              {/* <p>
                <MDBIcon icon="print" className="me-3" /> + 01 234 567 89
              </p> */}
            </MDBCol>
          </MDBRow>
        </MDBContainer>
      </section>
      <div className='text-center p-4' style={{ backgroundColor: '#000d32', color: '#fff' }}>
        <a href='/termsAndConditions' className='text-reset fw-bold mx-2'>
          Terms and Conditions
        </a>
        |
        <a href='/privacyPolicy' className='text-reset fw-bold mx-2'>
          Privacy Policy
        </a>
        <br />
        © 2024 Copyright:
        <a className='text-reset fw-bold' href='https://www.bharatonair.com/'>
          @bharatonair.com
        </a>
      </div>
    </MDBFooter>
  );
}