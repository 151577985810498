import React, { useEffect } from 'react';
import Navbar from '../Navbar/Navbar';
import HRService from '../assets/HRServices.jpg';
import Footer from '../Footer/Footer';
import './Contract.css';

const HRContract = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);

  return (
    <div>
      <Navbar />
      <section>
        <div className="container-about">
          <div className="text">
            <h1 style={{ color: '#010b96' }}>HR Consultant Outsourcing</h1>
            <p style={{ color: '#010b96' }}>
            HR consultant outsourcing involves hiring external firms to manage human resources functions such as recruitment, employee relations, payroll, and compliance. This is especially useful for small businesses that lack in-house HR expertise. Outsourcing HR services ensures compliance with legal requirements, access to expert advice, and cost savings by avoiding the need for a full-time HR department. While it offers efficiency, outsourcing HR can lead to concerns over control and data security, making it crucial to work with trusted HR partners.
            </p>
            <h5 style={{ color: '#010b96' }}>Responsibilities</h5>
            <ul style={{ color: '#010b96' }}>
              <li>Recruitment and management of workers</li>
              <li>Ensuring compliance with labour laws and regulations</li>
              <li>Maintaining worker performance and quality standards</li>
            </ul>
            <h5 style={{ color: '#010b96' }}>Deliverables</h5>
            <ul style={{ color: '#010b96' }}>
              <li>Timely completion of tasks/projects</li>
              <li>Meeting quality and productivity targets</li>
              <li>Compliance with safety protocols</li>
            </ul>
          </div>
          <div className="image">
            <img src={HRService} width={'100%'} style={{ height: 400 }} alt="Contract Labour" />
          </div>
        </div>
        <Footer />
      </section>
    </div>
  );
};

export default HRContract;
