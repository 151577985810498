// Refund.js
import React, { useState,useEffect  } from 'react';
import Navbar from '../../Navbar/Navbar';
import Footer from '../../Footer/Footer';
import './Refund.css';

const Refund = () => {
  const [refundData, setRefundData] = useState({
    orderId: '',
    reason: '',
    details: '',
  });
  const handleChange = (e) => {
    const { name, value } = e.target;
    setRefundData((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };
  useEffect(() => {
    // Scroll to top of the page when the component mounts
    window.scrollTo(0, 0);
  }, []);

  const handleSubmit = (e) => {
    e.preventDefault();
    // Handle refund request submission, e.g., send data to the backend
    console.log('Refund request submitted:', refundData);
  };

  return (
    <div>
      <Navbar />
      <div className="refund-container">
        <h1 style={{ color: '#010b96' }}>Request a Refund</h1>
        <form className="refund-form" onSubmit={handleSubmit}>
          <div className="form-group">
            <label htmlFor="orderId" style={{ color: '#010b96' }}>Order ID</label>
            <input
              type="text"
              id="orderId"
              name="orderId"
              value={refundData.orderId}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="reason" style={{ color: '#010b96' }}>Reason for Refund</label>
            <textarea
              id="reason"
              name="reason"
              value={refundData.reason}
              onChange={handleChange}
              required
            />
          </div>
          <div className="form-group">
            <label htmlFor="details" style={{ color: '#010b96' }}>Additional Details</label>
            <textarea
              id="details"
              name="details"
              value={refundData.details}
              onChange={handleChange}
            />
          </div>
          <button type="submit" className="refund-button">
            Submit Refund Request
          </button>
        </form>
      </div>
      <Footer />
    </div>
  );
};

export default Refund;
