import React from 'react';
import Navbar from '../Navbar/Navbar';
import Footer from '../Footer/Footer';
import image from '../assets/terms-contions.jpg';
import './Terms&Conditions.css'; // Assuming you have this CSS file for the styles

const TermsAndConditions = () => {
    return (
        <div>
            <Navbar />
         

            {/* Background image container */}
            <div
                style={{
                    backgroundImage: `url(${image})`,
                    backgroundSize: 'cover',
                    backgroundPosition: 'center',
                    backgroundRepeat: 'no-repeat',
                    minHeight: '100vh',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    animation: 'fadeIn 2s ease-in-out',
                }}
            >
                   <h1  style={{
            color: "#FFF",
            fontWeight: 700,
            fontSize: '2.5rem', // Increase font size for the main title
            margin: '0', 
            animation: 'fadeInDown 1s ease-in-out',
             fontFamily:'sans-serif',
             textShadow: '2px 2px 8px rgba(0, 0, 0, 0.5)'
          }}>Terms & Conditions</h1>
                {/* Scrollable terms container */}
                <div
                    className="terms-container"
                    style={{
                        maxHeight: '90vh',
                        overflowY: 'scroll',
                        backgroundColor: 'rgba(255, 255, 255, 0.8)', // Semi-transparent background
                        padding: '20px',
                        margin: '20px',
                        borderRadius: '10px',
                    }}
                >
                    <p style={{ color: '#010b96' }}><strong>Last Updated:</strong> August 19, 2024</p>

                    <h2 style={{ color: '#010b96', fontFamily: 'fantasy' }}>Bharat OnAir Corporate Site Terms</h2>
                    <p style={{ color: '#010b96' }}>
                        Bharat OnAir Corporate (“BOAC” or “we”) provides its content on its websites or applications
                        that post a link to this Terms of Use (the “Site”) subject to the following terms and conditions
                        (the “Terms”). We may periodically change the Terms without prior notice, so please check back
                        from time to time.By accessing and using this
                        Site, you agree to these Terms. For an explanation of BOAC’s practices and policies related to the
                        collection, use, and storage of our users’ information, please read our Privacy Policy.
                    </p>

                  
                    <h6 style={{ color: '#010b96', fontWeight: 'bold' }}>1. Copyrights</h6>
                    <p style={{ color: '#010b96', fontFamily: 'initial' }}>
                        All content and functionality on the Site, including text, graphics, logos, icons, images, and videos
                        and the selection and arrangement thereof, in addition to any concepts, know-how, tools, frameworks,
                        software, applications, or other technology, algorithms, models, processes, and industry perspectives
                        underlying or embedded in the foregoing, along with any enhancements to or derivative works thereof
                        (the “Site Content”) is the exclusive property of BOAC or its licensors and, to the extent applicable.
                        Neither the Site Content nor functionality of the Site, may be copied, reproduced, modified, reverse
                        engineered, altered (including the removal or disabling of any security or technological safeguards,
                        disclaimers, or legends) uploaded, published, uploaded, posted, transmitted, or distributed in any
                        way without our written permission.
                    </p>

                    <h6 style={{ color: '#010b96', fontWeight: 'bold' }}>2. Trademarks</h6>
                    <p style={{ color: '#010b96', fontFamily: 'initial' }}>
                        The trademarks, service marks, designs, and logos (collectively, the “Trademarks”) displayed on the Site
                        are the registered and unregistered Trademarks of BOAC and its licensors. You agree that, except as
                        expressly permitted by us (e.g., through social media sharing tools provided on the Site) or by our
                        licensors, where applicable, you will not refer to or attribute any information to BOAC or its licensors
                        in any public medium (e.g., press release, websites, or public social media) for advertising or promotion
                        purposes, or for the purpose of informing or influencing any third party and that you will not use or
                        reproduce any Trademark of, or imply any endorsement by or relationship with, BOAC or its licensors.
                    </p>

                    <h6 style={{ color: '#010b96', fontWeight: 'bold' }}>3. Use of Site Content</h6>
                    <p style={{ color: '#010b96', fontFamily: 'initial' }}>
                        BOAC hereby grants you a limited, non-exclusive, non-transferable, revocable license for the term hereof to
                        access and download, display, and print one copy of the Site Content on any single computer solely for your
                        internal, business use, provided that you do not modify the Site Content in any way (including creating
                        derivative works thereof), that you retain all copyright and other proprietary notices displayed on the Site
                        Content, and that you otherwise comply with these Terms. You may not otherwise reproduce, modify, reverse
                        engineer, distribute, transmit, post, or disclose the Site Content without BOAC’s prior written consent. In
                        addition, you may not “mirror” the Site Content or any portion thereof without BOAC’s express written consent.
                        Nothing on this Site should be construed as granting directly or indirectly, or by implication any license or
                        right to use any BOAC intellectual property other than as expressly set forth herein. The license granted in
                        this section terminates automatically and immediately if you do not comply with these Terms.
                    </p>

                    <h6 style={{ color: '#010b96', fontWeight: 'bold' }}>4. User Postings</h6>
                    <p style={{ color: '#010b96', fontFamily: 'initial' }}>
                        You acknowledge and agree that BOAC shall own and have the unrestricted right to use, publish, and otherwise
                        exploit any and all information that you post or otherwise publish on the Site in postings, forums or message
                        boards, questionnaire, survey responses, and otherwise, and you acknowledge and agree that, by providing us
                        any such submission, you automatically grant, and hereby do grant, to us a worldwide, non-exclusive,
                        transferable, assignable, sublicensable, fully paid-up, royalty-free, perpetual, irrevocable license and right
                        to use, reproduce, publish, distribute, modify and otherwise exploit such submission for any purpose, and in
                        any form or media, not prohibited by applicable law. In addition, you hereby waive any claims against BOAC for
                        any alleged or actual infringements of any rights of privacy or publicity, intellectual property rights, moral
                        rights, or rights of attribution in connection with BOAC’s use and publication of such submissions.
                    </p>
                    <p style={{ color: '#010b96', fontFamily: 'initial' }}>
                        You covenant that you shall not post or otherwise publish on the Site any materials that (a) are threatening,
                        libelous, defamatory, or obscene; (b) would constitute, or that encourage conduct that would constitute, a
                        criminal offense, give rise to civil liability, or otherwise violate law; (c) infringe the intellectual
                        property, privacy, or other rights of any third parties; (d) contain a computer virus or other destructive
                        element; (e) contain advertising; (f) constitute or contain false or misleading statements; or (g) violates
                        these Terms.
                    </p>
                    <p style={{ color: '#010b96', fontFamily: 'initial' }}>
                        BOAC does not represent or endorse the accuracy of reliability of information posted to the Site by users.
                        In addition, BOAC does not and cannot review all information posted to the Site by users and is not responsible
                        for such information. However, BOAC reserves the right to refuse to post and the right to remove any information,
                        in whole or in part, for any reason or for no reason.
                    </p>

                    <h6 style={{ color: '#010b96', fontWeight: 'bold' }}>5. Notices of Infringement and Takedown by BOAC</h6>
                    <p style={{ color: '#010b96', fontFamily: 'initial' }}>
                        BOAC prohibits the posting of any information that infringes or violates the copyright rights and/or other
                        intellectual property rights (including rights of privacy and publicity) of any person or entity. If you
                        believe that your intellectual property right (or such a right that you are responsible for enforcing) is
                        infringed by any content on the Site, please write to BOAC at the address shown below, giving a written
                        statement that contains: (a) identification of the copyrighted work and/or intellectual property right
                        claimed to have been infringed; (b) identification of the allegedly infringing material on the Site that
                        is requested to be removed; (c) your name, address, and daytime telephone number, and an e-mail address if
                        available; (d) a statement that you have a good faith belief that the use of the copyrighted work and/or
                        exercise of the intellectual property right is not authorized by the owner, its agent, or the law; (e) a
                        statement that the information in the notification is accurate, and, under penalty of perjury, that the
                        signatory is authorized to act on behalf of the owner of the right that is allegedly infringed; and (f) the
                        signature of the intellectual property right owner or someone authorized on the owner’s behalf to assert
                        infringement of the right. BOAC will remove any posted submission that infringes the copyright or other
                        intellectual property Under appropriate circumstances, persons who repeatedly submit infringing or unlawful
                        material will be prohibited from posting further submissions. BOAC’s contact for submission of notices under
                        this Section 5 is: Legal Department, Pulsebay, No:64 Subbiah Salai, 2nd Floor Pondicherry-605001.
                    </p>

                    <h6 style={{ color: '#010b96', fontWeight: 'bold' }}>6. Disclaimers</h6>
                    <p style={{ color: '#010b96', fontFamily: 'initial' }}>
                        THE CONTENT AND FUNCTIONALITY ON THE SITE IS PROVIDED WITH THE UNDERSTANDING THAT BOAC IS NOT HEREIN ENGAGED
                        IN RENDERING PROFESSIONAL ADVICE OR SERVICES TO YOU, NO SITE CONTENT IS INTENDED TO SERVE AS OR SHALL BE DEEMED
                        INVESTMENT, LEGAL, TAX, ACCOUNTING OR OTHER REGULATED ADVICE, AND THAT YOU SHALL REMAIN SOLELY RESPONSIBLE FOR
                        YOUR USE OF ALL SITE CONTENT AND ACKNOWLEDGE THAT ANY RELIANCE UPON THE SITE CONTENT SHALL BE ENTIRELY AT YOUR
                        SOLE OPTION AND RISK. ALL CONTENT AND FUNCTIONALITY ON THE SITE IS PROVIDED “AS IS,” WITHOUT WARRANTY OF ANY
                        KIND, EITHER EXPRESS OR IMPLIED, INCLUDING, WITHOUT LIMITATION, IMPLIED WARRANTIES OF MERCHANTABILITY AND
                        FITNESS FOR A PARTICULAR PURPOSE. BOAC AND ITS THIRD-PARTY CONTENT PROVIDERS MAKE NO WARRANTIES, EXPRESS OR
                        IMPLIED, AS TO THE OWNERSHIP, ACCURACY, OR ADEQUACY OF THE SITE CONTENT. BOAC SHALL HAVE NO LIABILITY OR
                        RESPONSIBILITY FOR ANY INFORMATION PUBLISHED ON LINKED WEBSITES, CONTAINED IN ANY USER SUBMISSIONS PUBLISHED
                        ON THE SITE, OR PROVIDED BY THIRD PARTIES. NEITHER BOAC NOR ITS THIRD-PARTY CONTENT PROVIDERS SHALL BE LIABLE
                        FOR ANY INDIRECT, INCIDENTAL, CONSEQUENTIAL, OR PUNITIVE DAMAGES OR FOR LOST REVENUES OR PROFITS, WHETHER OR
                        NOT ADVISED OF THE POSSIBILITY OF SUCH DAMAGES OR LOSSES AND REGARDLESS OF THE THEORY OF LIABILITY.
                    </p>

                    <h6 style={{ color: '#010b96', fontWeight: 'bold' }}>7. Indemnification</h6>
                    <p style={{ color: '#010b96', fontFamily: 'initial' }}>
                    You hereby indemnify, defend, and hold harmless BOAC and all of its predecessors, successors, parents, subsidiaries, affiliates, officers, directors, shareholders, investors, employees, agents, representatives, and attorneys and their respective heirs, successors, and assigns (“BOAC Indemnified Parties”) from and against any and all liability, expenses, costs, or other losses (“Losses”) incurred by BOAC and/or BOAC Indemnified Parties in connection to any claims arising out of your use of the Site and/or any breach by you of these Terms, including the representations, warranties and covenants you made, if any, by agreeing to these Term. BOAC reserves the right to assume, at its own expense, the exclusive defense and control of any matter otherwise subject to indemnification by you.
                    </p>
                    <h6 style={{ color: '#010b96', fontWeight: 'bold' }}>8. Third-party websites & Providers</h6>
                    <p style={{ color: '#010b96', fontFamily: 'initial' }}>
                        We may provide links to third-party websites, and some of the content appearing to be on this Site is in fact supplied, supported, or provided directly or indirectly by third parties, for example, in instances of framing of third-party websites or incorporation through framesets of content supplied by third-party servers. BOAC has no responsibility for these third-party websites, which are governed by the terms of use and privacy policies, if any, of the applicable third-party content providers.
                    </p>
                    <h6 style={{ color: '#010b96', fontWeight: 'bold' }}>9. Governing Law; Jurisdiction</h6>
                    <p style={{ color: '#010b96', fontFamily: 'initial' }}>
                        These Terms shall be governed by and construed in accordance with the laws of [Bharat OnAir Corporate/Tamilnadu&Pondicherry], without regard to its conflict of law provisions.
                        Any legal action or proceeding arising under or in connection with these Terms shall be brought exclusively in the courts of [Tamilnadu&Puducherry&India], and you hereby consent to the exclusive jurisdiction of such courts.
                    </p>   
                    <h6 style={{ color: '#010b96', fontWeight: 'bold' }}>10. No Refund Policy</h6>
                    <p style={{ color: '#010b96', fontFamily: 'initial' }}>
                    We don't any refund for any payments
                    </p>      
                </div>
            </div>

            <Footer />
        </div>
    );
};

export default TermsAndConditions;
