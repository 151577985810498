import React from 'react'
import { useNavigate } from 'react-router-dom'
import './CardAni.css';
import backgroundImg from '../assets/back.jpg'

const CardServices = () => {
  const navigate = useNavigate()
  return (
    <div>
    <div class="box-container">
	<div class="box-item">
    <div class="flip-box">
      <div class="flip-box-front text-center" style={{backgroundImage: `url(${backgroundImg})`}}>
        <div class="inner color-white">
          <h3 class="flip-box-header">Contract Labour</h3>
          <img  src="https://s25.postimg.cc/65hsttv9b/cta-arrow.png" alt="" class="flip-box-img"/>
        </div>
      </div>
      <div class="flip-box-back text-center" style={{backgroundImage: `url(${backgroundImg})`}}>
        <div class="inner color-white">
          <h3 class="flip-box-header"></h3>
          <p>Click on know more about Contract Labour</p>
          <button class="flip-box-button"  onClick={() => {
                  navigate(`/contractLabour`)
                }}>Learn More</button>
        </div>
      </div>
    </div>
	</div>
  <div class="box-item">
    <div class="flip-box">
      <div class="flip-box-front text-center" style={{backgroundImage: `url(${backgroundImg})`}}>
        <div class="inner color-white">
          <h3 class="flip-box-header">HR Consultant</h3>
          <img src="https://s25.postimg.cc/65hsttv9b/cta-arrow.png" alt="" class="flip-box-img"/>
        </div>
      </div>
      <div class="flip-box-back text-center" style={{backgroundImage: `url(${backgroundImg})`}}>
        <div class="inner color-white">
        <p>Click on know more about HR Consultant</p>
          <button class="flip-box-button" onClick={() => {
                  navigate(`/hrConsultant`)
                }}>Learn More</button>
        </div>
      </div>
    </div>
	</div>
	<div class="box-item">
    <div class="flip-box">
      <div class="flip-box-front text-center" style={{backgroundImage: `url(${backgroundImg})`}}>
        <div class="inner color-white">
          <h3 class="flip-box-header">Transport Services</h3>
          <img src="https://s25.postimg.cc/65hsttv9b/cta-arrow.png" alt="" class="flip-box-img"/>
        </div>
      </div>
      <div class="flip-box-back text-center" style={{backgroundImage: `url(${backgroundImg})`}}>
        <div class="inner color-white">
          <h3 class="flip-box-header"></h3>
          <p>Click on know more about Transport Services</p>
          <button class="flip-box-button" onClick={() => {
                  navigate(`/transport`)
                }}>Learn More</button>
        </div>
      </div>
    </div>
	</div>
  <div class="box-item">
    <div class="flip-box">
      <div class="flip-box-front text-center" style={{backgroundImage: `url(${backgroundImg})`}}>
        <div class="inner color-white">
          <h3 class="flip-box-header">Security Services</h3>
          <img src="https://s25.postimg.cc/65hsttv9b/cta-arrow.png" alt="" class="flip-box-img"/>
        </div>
      </div>
      <div class="flip-box-back text-center" style={{backgroundImage: `url(${backgroundImg})`}}>
        <div class="inner color-white">
          <h3 class="flip-box-header"></h3>
          <p>Click on know more about Security Services</p>
          <button class="flip-box-button" onClick={() => {
                  navigate(`/securityService`)
                }}>Learn More</button>
        </div>
      </div>
    </div>
	</div>
</div>

    </div>

  )
}

export default CardServices