import React, { useEffect } from 'react';
import Navbar from '../Navbar/Navbar';
import about from '../assets/about.jpg'
import Footer from '../Footer/Footer'
import './About.css'

const About = () => {
  useEffect(() => {
    window.scrollTo(0, 0);
  }, []);
  return (
    <div>
      <Navbar />
      <section>
      <div class="container-about">
      <div class="text">
              <h1 style={{ alignItems: 'center', color: '#010b96'}}>What we Are</h1>
                <p style={{ alignItems: 'center', color: '#010b96'}}>Bharat On Air Corporate (P) Limited (BOAC) is India’s leading business services provider, leveraging our extensive domain knowledge and future-ready digital platforms to drive client productivity through outsourced solutions.</p>
                <p class="py-3" style={{ alignItems: 'center', color: '#010b96' }}>We provide a host of technology-enabled staffing and managed outsourcing services across processes such as sales & marketing, customer care, after-sales service, back-office operations, telecom operations, manufacturing operations, facilities and security management, HR & F&A operations, IT & mobility services, etc.
Our passion for delivering exceptional services, augmented by proprietary digital platforms, has strongly established our credentials as India’s largest employer in the private sector and the biggest integrated business services provider in the country.
                </p>
              </div>
        <div class="image">
            <img src={about} width="500" height="350" alt="Description of the image"/>
            <p class="py-3" style={{ alignItems: 'left',textAlign:'left', color: '#010b96' }}>A core value driving our business is constantly making the workforce more productive. Our business strategy is aligned with this, including training and skill development for better employability, helping job seekers easily find employment opportunities, digitising workflows, and providing social security benefits to a wider employable population</p>
        </div>
    </div>
  <Footer/>
      </section>
</div>
  );
};

export default About;