import React from 'react'
import './Form.css'

const Form = () => {
  return (
    <div class="main-job-application-form">			
<h4 style={{ alignItems: 'center', color: '#010b96'}} >Contact Form</h4>
<form action="#" method="POST" enctype="multipart/form-data"/>
<label for="name">Name</label>
<input type="text" id="name" name="name" class="name" placeholder="Enter name" required/>
<label for="email">Email</label>
<input type="text" id="email" name="email" class="email" placeholder="Enter email" required/>
<label for="number">Phone Number</label>
<input type="text" id="number" name="number" class="number" placeholder="Enter your number" required/>
<label for="location">Location</label>
<select name="location" class="location" id="location" placeholder="" required>
<option value="">Pondicherry</option>
<option value="">Chennai</option>
<option value="">Bangalore</option>
<option value="">Hyderabad</option>
<option value="">Pune</option>
</select>
<label for="cover">Subject</label>
<textarea name="cover" class="cover" id="cover" placeholder="Write something..." rows="5" required></textarea>
<input name="submit" type="submit" value="Submit"/>
<form/>
</div>
  )
}
export default Form